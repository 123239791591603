/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./profile-list.component";
var styles_ProfileListComponent = [i0.styles];
var RenderType_ProfileListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileListComponent, data: {} });
export { RenderType_ProfileListComponent as RenderType_ProfileListComponent };
function View_ProfileListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-outline-danger mb-2 mb-md-0 mr-2 test-delete-assetprofile"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-trash-o fa-lg fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-outline-primary mb-2 mb-md-0 mr-2 test-details-assetprofile"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDetails(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-eye fa-lg fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Details"]))], null, null); }
function View_ProfileListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileListComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showButton; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getProfileInfo(_v.context.$implicit); _ck(_v, 3, 0, currVal_0); }); }
function View_ProfileListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" No Asset Profiles Found for ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.scope; _ck(_v, 1, 0, currVal_0); }); }
function View_ProfileListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileListComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileListComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profiles; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.profiles.length === 0); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ProfileListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileListComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profiles; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProfileListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-profile-list", [], null, null, null, View_ProfileListComponent_0, RenderType_ProfileListComponent)), i1.ɵdid(1, 573440, null, 0, i3.ProfileListComponent, [], null, null)], null, null); }
var ProfileListComponentNgFactory = i1.ɵccf("xcm-profile-list", i3.ProfileListComponent, View_ProfileListComponent_Host_0, { showButton: "showButton", profiles: "profiles" }, { onDelete: "onDelete", onEdit: "onEdit" }, []);
export { ProfileListComponentNgFactory as ProfileListComponentNgFactory };
