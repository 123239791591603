

import {Injectable} from '@angular/core';
import {SapConfig} from 'app/login/sap.config';
import {Observable} from 'rxjs';

import {Auth} from './auth';

@Injectable()
export class AuthenticationService {
  // Check http://www.baeldung.com/angular-4-upgrade-for-spring-security-oauth/

  // store the URL so we can redirect after logging in
  redirectUrl: string;
  private accessToken: string;
  private idToken: string;
  private expiresTimerId: any;
  private idTokenCache: any;
  // - (dash) and _ (underscore) are here to replace + and / for URLs
  // This does not work depending on the string size since there is no complement ==
  // readonly base64Regex: RegExp =
  //    /^(?:[-_A-Za-z0-9+/]{4})*(?:[-_A-Za-z0-9+/]{2}==|[-_A-Za-z0-9+/]{3}=)?$/;

  readonly base64UrlEncodedRegex: RegExp = /^(?:[-_A-Za-z0-9+/=])+$/;

  constructor() {}

  getAuthorizationHeader() {
    let authHeader = '';
    if (this.isIdTokenValid()) {
      const bearerToken = localStorage.getItem('xcm_access_token');
      if (bearerToken) {
        authHeader = `Bearer ${bearerToken}`;
      }
    }
    return authHeader;
  }

  get isLoggedIn(): boolean {
    return localStorage.getItem('xcm_access_token') !== null && this.isIdTokenValid();
  }

  isIdTokenValid() {
    const idtoken = this.idTokenPayload;
    return (idtoken.iss === 'accounts.amadeus.com') && (idtoken.exp * 1000 > Date.now());
  }

  get idTokenPayload(): any {
    if (!this.idTokenCache || Object.keys(this.idTokenCache).length === 0) {
      let idTokenPayload = {};
      const xcmIdToken = localStorage.getItem('xcm_id_token');
      if (xcmIdToken && xcmIdToken.match(this.base64UrlEncodedRegex)) {
        idTokenPayload = JSON.parse(atob(xcmIdToken));
      }
      this.idTokenCache = idTokenPayload;
    }
    return this.idTokenCache;
  }

  get usap() {
    let usap = '';
    const idtoken = this.idTokenPayload;
    if (idtoken) {
      const auth = new Auth();
      if (auth.guessPhaseFromLocation() === 'usr') {
        usap = SapConfig.localSap;
      } else {
        const SapConfigUsap = SapConfig.usap[idtoken.env] || SapConfig.usap['DEFAULT'];
        const usaproot =
            (SapConfigUsap[idtoken.organization] || SapConfigUsap['all_star'] ||
             this.computeUsap(idtoken));
        usap = `${usaproot}${SapConfig.getPhaseLetter(idtoken.env)}`;
      }
    }
    return usap;
  }

  private computeUsap(idtoken: any): string {
    let sap = '';
    if (idtoken) {
      const orgaMaxSize = 4;
      const orgaSAP = idtoken.organization.replace('-', '').substring(0, orgaMaxSize);
      sap = `1ASIUSASXCM${orgaSAP}`;
    }
    return sap;
  }

  login(fragment: string = ''): Observable<boolean> {
    return new Observable((obs: any) => {
      const auth: Auth = new Auth();
      if (!fragment && !this.isLoggedIn) {
        // console.log('fragment is ', fragment);
        location.assign(auth.url(auth.guessPhaseFromLocation()));
      }
      const re: RegExp = /access_token=([^&]+)&id_token=([^&]+)&token_type=(\w+)&expires_in=(\d+)/;

      try {
        if (fragment) {
          const match = re.exec(fragment);
          if (match) {
            // console.log((new Date()).getSeconds(), match[1], match[2], match[3], match[4]);

            this.accessToken = match[1];
            this.idToken = match[2];

            this.saveIdToken();
            // expire the token
            this.startExpiresTimer(+match[4]);
            localStorage.setItem('xcm_access_token', this.accessToken);
            // emit true
            obs.next(true);
            obs.complete();
          } else {
            console.log(`${fragment} does not match`);
          }
        }
      } catch (err) {
        console.log(err);
        obs.throw(err);
      }
    });
  }

  saveIdToken() {
    const tokens = this.idToken.split('.');
    if (tokens.length === 3) {
      // url decode instead => modify _ by / and - by +
      const idTokenb64 = tokens[1].replace('_', '/').replace('-', '+');
      localStorage.setItem('xcm_id_token', idTokenb64);
    }
  }

  logout() {
    this.expiresTimerId = null;
    this.idTokenCache = undefined;
    localStorage.removeItem('xcm_id_token');
    localStorage.removeItem('xcm_access_token');
  }

  private startExpiresTimer(seconds: number) {
    if (this.expiresTimerId) {
      clearTimeout(this.expiresTimerId);
    }
    this.expiresTimerId = setTimeout(() => {
      console.log('Session has expired');
      this.logout();
    }, seconds * 1000);
  }
}
