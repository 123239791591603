/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./asset-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../asset-detail/asset-detail.component.ngfactory";
import * as i4 from "../asset-detail/asset-detail.component";
import * as i5 from "../../../core/assetprofile.service";
import * as i6 from "../asset-edit/asset-edit.component.ngfactory";
import * as i7 from "../asset-edit/asset-edit.component";
import * as i8 from "./asset-view.component";
import * as i9 from "../../../core/assetgroup.service";
import * as i10 from "@angular/router";
var styles_AssetViewComponent = [i0.styles];
var RenderType_AssetViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssetViewComponent, data: {} });
export { RenderType_AssetViewComponent as RenderType_AssetViewComponent };
function View_AssetViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["aria-label", "Edit"], ["class", "btn btn-default"], ["href", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.preventDefault();
        var pd_0 = (_co.onEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-pencil fa-2x"]], null, null, null, null, null))], null, null); }
function View_AssetViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Working on ", " "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetViewComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "xcm-asset-detail", [], null, null, null, i3.View_AssetDetailComponent_0, i3.RenderType_AssetDetailComponent)), i1.ɵdid(7, 573440, null, 0, i4.AssetDetailComponent, [i5.AssetprofileService], { asset: [0, "asset"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.authorizations$)).U_PAYLOAD; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.parent.context.ngIf; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = ((_v.parent.context.ngIf.profile == null) ? null : _v.parent.context.ngIf.profile.label); _ck(_v, 2, 0, currVal_0); }); }
function View_AssetViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-asset-edit", [], null, [[null, "onCancel"], [null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onCancel" === en)) {
        var pd_0 = (_co.exitEditMode() !== false);
        ad = (pd_0 && ad);
    } if (("onSave" === en)) {
        var pd_1 = (_co.onSave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_AssetEditComponent_0, i6.RenderType_AssetEditComponent)), i1.ɵdid(1, 638976, null, 0, i7.AssetEditComponent, [i5.AssetprofileService], { asset: [0, "asset"] }, { onSave: "onSave", onCancel: "onCancel" })], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AssetViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetViewComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetViewComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editMode; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.editMode; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_AssetViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-outline-primary mb-2 mb-md-0 mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-chevron-left fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Back"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "row top-buffer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetViewComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.asset$)); _ck(_v, 6, 0, currVal_0); }, null); }
export function View_AssetViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-asset-view", [], null, null, null, View_AssetViewComponent_0, RenderType_AssetViewComponent)), i1.ɵdid(1, 114688, null, 0, i8.AssetViewComponent, [i9.AssetgroupService, i10.ActivatedRoute, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssetViewComponentNgFactory = i1.ɵccf("xcm-asset-view", i8.AssetViewComponent, View_AssetViewComponent_Host_0, {}, {}, []);
export { AssetViewComponentNgFactory as AssetViewComponentNgFactory };
