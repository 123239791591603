
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AssetprofileService} from 'app/core/assetprofile.service';
import {AssetProfile} from 'app/shared/models/asset';
import {Scope} from 'app/shared/models/scope';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'xcm-provider-profile',
  templateUrl: './provider-profile.component.html',
  styleUrls: ['./provider-profile.component.css']
})
export class ProviderProfileComponent implements OnInit {
  private saveForCreation: boolean;
  showForm: boolean;
  selectedScope: Scope;
  assetprofile: AssetProfile;
  profiles$: Observable<AssetProfile[]>;
  scopes$: Observable<Scope[]>;
  constructor(private assetProfileService: AssetprofileService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.scopes$ = this.route.data.pipe(map(data => data.grantedScopes));
  }

  getScope(scope: Scope) {
    return scope.label + (scope.description ? ` - ${scope.description}` : '');
  }

  onScopeSelected(scope: Scope) {
    this.profiles$ = this.assetProfileService.getAssetProfilesByScope(scope.label);
    this.selectedScope = scope;
  }

  edit(assetprofile: AssetProfile) {
    this.assetprofile = assetprofile;
    this.saveForCreation = false;
    this.showForm = true;
  }

  create() {
    this.assetprofile = new AssetProfile();
    this.showForm = true;
    this.saveForCreation = true;
  }

  delete(profile: AssetProfile) {
    if (profile.id) {
      this.assetProfileService.deleteAssetProfile(profile.id).subscribe(() => {
        this.profiles$ = this.assetProfileService.getAssetProfilesByScope(this.selectedScope.label);
      });
    }
  }


  cancel() {
    this.showForm = false;
  }

  exitForm() {
    this.showForm = false;
  }


  onSave(assetprofile: AssetProfile) {
    if (this.saveForCreation) {
      this.assetProfileService.addAssetProfile(assetprofile).subscribe(() => this.exitForm());
    } else {
      this.assetProfileService.updateAssetProfile(assetprofile).subscribe(() => this.exitForm());
    }
  }
}
