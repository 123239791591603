/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./asset-create.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../../shared/dynamic-form/dynamic-form.component.ngfactory";
import * as i5 from "../../../shared/dynamic-form/dynamic-form.component";
import * as i6 from "../../../shared/dynamic-form/question-control.service";
import * as i7 from "./asset-create.component";
import * as i8 from "../../../core/assetprofile.service";
import * as i9 from "../../../core/assetgroup.service";
var styles_AssetCreateComponent = [i0.styles];
var RenderType_AssetCreateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssetCreateComponent, data: {} });
export { RenderType_AssetCreateComponent as RenderType_AssetCreateComponent };
function View_AssetCreateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, ["", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getLabel(_v.context.$implicit); _ck(_v, 3, 0, currVal_2); }); }
function View_AssetCreateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "select", [["class", "custom-select"], ["id", "selectedprofile"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.selectedprofile = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.onSystemChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetCreateComponent_2)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.selectedprofile; _ck(_v, 3, 0, currVal_7); var currVal_8 = _v.context.ngIf; _ck(_v, 7, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_AssetCreateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-dynamic-form", [], null, [[null, "onSave"], [null, "onCancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("onCancel" === en)) {
        var pd_1 = (_co.onCancelled() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_DynamicFormComponent_0, i4.RenderType_DynamicFormComponent)), i1.ɵdid(1, 573440, null, 0, i5.DynamicFormComponent, [i6.QuestionControlService], { questions: [0, "questions"] }, { onSave: "onSave", onCancel: "onCancel" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.questions; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AssetCreateComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "form-group row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "col-sm-2 col-form-label"], ["for", "selectedprofile"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["External System"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "col-10"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetCreateComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetCreateComponent_3)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.assetprofiles$)); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.selectedprofile; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_AssetCreateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-asset-create", [], null, null, null, View_AssetCreateComponent_0, RenderType_AssetCreateComponent)), i1.ɵdid(1, 573440, null, 0, i7.AssetCreateComponent, [i8.AssetprofileService, i9.AssetgroupService], null, null)], null, null); }
var AssetCreateComponentNgFactory = i1.ɵccf("xcm-asset-create", i7.AssetCreateComponent, View_AssetCreateComponent_Host_0, { assetgroupId: "assetgroupId" }, { onCancel: "onCancel", onSave: "onSave" }, []);
export { AssetCreateComponentNgFactory as AssetCreateComponentNgFactory };
