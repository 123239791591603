import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {AssetprofileService} from 'app/core/assetprofile.service';
import {Asset, AssetPayload} from 'app/shared/models/asset';
import {QuestionBase} from 'app/shared/question-model/question-base';

@Component({
  selector: 'xcm-asset-edit',
  templateUrl: './asset-edit.component.html',
  styleUrls: ['./asset-edit.component.css']
})
export class AssetEditComponent implements OnInit, OnChanges {
  questions: QuestionBase<any>[];

  @Input() asset: Asset;
  @Output() onSave = new EventEmitter<Asset>();
  @Output() onCancel = new EventEmitter<any>();
  constructor(private assetprofileService: AssetprofileService) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.asset) {
      this.questions = this.assetprofileService.getAssetQuestions(this.asset);
    }
  }

  onSubmit(payload: Object) {
    const asset: Asset = Object.assign({}, this.asset);
    asset.payload = [];
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const assetPayload: AssetPayload = new AssetPayload();
        assetPayload.profileElementFormatId = key;
        assetPayload.value = payload[key] ? payload[key].toString() : '';
        asset.payload.push(assetPayload);
      }
    }
    this.onSave.emit(asset);
  }


  onCancelled() {
    this.onCancel.emit(null);
  }
}
