import * as ControlType from 'app/shared/question-model/control-type';

import {SingleSelectQuestion} from './question-single-select';

export class RadiosQuestion extends SingleSelectQuestion {
  type: string;
  constructor(options: {} = {}) {
    super(options);
    this.controlType = ControlType.RADIOS;
    this.type = 'radio';
    // WARN Do not bind radio type in template, it should be explicitely type="radio" and not
    // [type]="option.type"
  }
}
