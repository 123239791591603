import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

import {AuthenticationService} from '../login/authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = this.authenticationService.getAuthorizationHeader();
    let authReq = req;
    // Clone the request to add the new header.
    if (authHeader) {
      authReq = req.clone({
        setHeaders: {
          Authorization: authHeader,
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache'
        }
      });
      authReq = authReq.clone({url: `/${this.authenticationService.usap}${authReq.url}`});
    } else {
      this.router.navigate(['/login']);
    }

    return next.handle(authReq);
  }
}
