import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AuthorizationService } from 'app/core/authorization.service';
import { AuthenticationService } from 'app/login/authentication.service';
import { AuthorizationData } from 'app/shared/models/authorization';
import { filter, map } from 'rxjs/operators';
var HeaderNavComponent = /** @class */ (function () {
    function HeaderNavComponent(store, authService, authorizationService, router) {
        this.store = store;
        this.authService = authService;
        this.authorizationService = authorizationService;
        this.router = router;
    }
    HeaderNavComponent.prototype.ngOnInit = function () {
        this.isexpanded = false;
        this.scope$ = this.store.select('scope').pipe(filter(function (scopes) { return !!scopes && scopes.length > 0; }), map(function (scopes) { return scopes[0]; }));
        this.metagroup$ = this.store.pipe(select('metagroup'));
        this.authorizations$ =
            this.authorizationService.getBasicAuthorizations().pipe(map(function (authorizations) {
                var authorizationData = new AuthorizationData();
                authorizations.forEach(function (authorization) {
                    authorizationData[authorization.label] = authorization.allow;
                });
                return authorizationData;
            }));
    };
    Object.defineProperty(HeaderNavComponent.prototype, "isLoggedIn", {
        get: function () {
            return this.authService.isLoggedIn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderNavComponent.prototype, "userInfo", {
        get: function () {
            return this.authService.idTokenPayload;
        },
        enumerable: true,
        configurable: true
    });
    HeaderNavComponent.prototype.collapse = function () {
        this.isexpanded = !this.isexpanded;
    };
    HeaderNavComponent.prototype.logout = function () {
        this.router.navigate(['/login']);
    };
    return HeaderNavComponent;
}());
export { HeaderNavComponent };
