<p>Total entities: {{ totalEntities }}</p>
<p>Selected entities: {{ selectedEntitiesCount }}</p>    
<ng-container *ngIf="entities$ | async as members; else nomembers">
  <div *ngIf="members.length > 0; else nomembers" [style.cursor]="processing ? 'wait' : 'pointer'">
  <xcm-filter-input [collection]="members" [filtercallback]="filterCallback" (onChange)="setFilteredEntities($event)"></xcm-filter-input>
    <form (ngSubmit)="onSubmit()" #myform=ngForm>
      <div class="form-group row" style="margin-top: 10px; margin-left: auto;">
        <div ngbDropdown class="d-inline-block">
        <div class="dropdown-label">Sort:</div>
            <select name="sortMenu" ngbDropdown class="btn btn-outline-primary" [(ngModel)]="sortAscending" (change)="onSort()" [ngModelOptions]="{standalone: true}">
              <option disabled>Select sort order</option>  
              <option [ngValue]="true">Ascending (a-z)</option>
              <option [ngValue]="false">Descending (z-a)</option>
            </select>
        </div>
      </div>
      <h2 style="margin-top: 2px; margin-bottom: auto;" i18n>Select Entities</h2><br>
      <!-- check box to select all/unselect all --> 
      <h3><input style="margin-bottom:3px;" type="checkbox" name="selectAll" (change)="selectAllUnselectAll()" id="selectAllCheckbox"> 
      <label style="margin-left:6px;" for="selectAllCheckbox">Select all/ Unselect all</label></h3>  
      <div *ngFor="let entity of filteredEntities$ | async; let i = index" class="form-check" style="margin-bottom: 10px;">
        <label style="margin-left: 15px;" class="form-check-label">
          <input type="checkbox" name="entity{{i}}" class="form-check-input test-select-entity" [checked]="entity.checked" (click)="entity.checked = !entity.checked; updateSelectedCount()">
          <span>{{ entity.label }}</span>
        </label>
      </div>
      <button type="submit" [disabled]="noSelection()" class="btn btn-primary mb-2 mb-md-0 mr-2 test-link-entities" i18n><i *ngIf="!processing; else doprocessing" class="fa fa-link fa-fw" aria-hidden="true"></i><ng-template #doprocessing><i class="fa fa-spinner fa-spin fa-fw"></i></ng-template>&nbsp;Link to selected entities</button>
      <button type="button" (click)="onCancelled()" class="btn btn-outline-primary mb-2 mb-md-0 mr-2 test-cancel" i18n>Cancel</button>
    </form>
  </div>
</ng-container>
<ng-template #nomembers>
  <label style="margin-top: 10px; color: #808080;" i18n><em>All Entities are already linked.</em></label>
</ng-template>