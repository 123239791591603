
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AssetgroupService} from 'app/core/assetgroup.service';
import {Asset} from 'app/shared/models/asset';
import {AuthorizationData} from 'app/shared/models/authorization';
import {Observable} from 'rxjs';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';

@Component({
  selector: 'xcm-asset-view',
  templateUrl: './asset-view.component.html',
  styleUrls: ['./asset-view.component.css']
})
export class AssetViewComponent implements OnInit {
  asset$: Observable<Asset>;
  private reloadasset$: Observable<Asset>;
  authorizations$: Observable<AuthorizationData>;
  editMode: boolean;
  private assetgroupId: string;
  private assetId: string;
  constructor(
      private assetgroupService: AssetgroupService, private route: ActivatedRoute,
      private router: Router) {}

  ngOnInit() {
    this.authorizations$ = this.route.data.pipe(map(data => data.authorizations));
    this.asset$ = this.route.paramMap.pipe(switchMap((params) => {
      this.assetgroupId = params.get('id') || '0';
      this.assetId = params.get('assetId') || '0';
      this.reloadasset$ = this.assetgroupService.getAsset(this.assetgroupId, this.assetId);
      return this.assetgroupService.getAsset(this.assetgroupId, this.assetId);
    }));
  }

  exitEditMode() {
    this.editMode = false;
  }

  onEdit() {
    this.editMode = true;
  }

  back() {
    this.router.navigate(['../..'], {relativeTo: this.route});
  }

  onSave(asset: Asset) {
    return this.assetgroupService.updateAsset(this.assetgroupId, asset).subscribe(() => {
      this.asset$ = this.reloadasset$.pipe(
          publishReplay(),
          refCount(),
      );
      this.exitEditMode();
    });
  }
}
