import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthenticationGuardService} from 'app/core/authentication-guard.service';
import {environment} from 'environments/environment';

const routes: Routes = [
  {
    path: 'assetgroup',
    loadChildren: 'app/assetgroup/assetgroup.module#AssetgroupModule',
    canLoad: [AuthenticationGuardService]
  },
  {
    path: 'provider-profile',
    loadChildren: 'app/provider-profile/provider-profile.module#ProviderProfileModule',
    canLoad: [AuthenticationGuardService]
  },
  {path: 'login', loadChildren: 'app/login/login.module#LoginModule'},
  {path: '', redirectTo: 'assetgroup', pathMatch: 'full'},
  {path: '**', redirectTo: 'assetgroup', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(
      routes, {enableTracing: !environment.production, preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
