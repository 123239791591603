import { EventEmitter, OnChanges } from '@angular/core';
import { QuestionControlService } from './question-control.service';
var DynamicFormComponent = /** @class */ (function () {
    function DynamicFormComponent(questionControlService) {
        this.questionControlService = questionControlService;
        this.onSave = new EventEmitter();
        this.onCancel = new EventEmitter();
    }
    Object.defineProperty(DynamicFormComponent.prototype, "questions", {
        get: function () {
            return this.sortedquestions;
        },
        set: function (questions) {
            this.sortedquestions = questions.sort(function (q1, q2) { return +q1.order - +q2.order; });
        },
        enumerable: true,
        configurable: true
    });
    DynamicFormComponent.prototype.ngOnChanges = function () {
        this.form = this.questionControlService.toFormGroup(this.questions);
    };
    DynamicFormComponent.prototype.onSubmit = function () {
        this.onSave.emit(this.form.value);
    };
    DynamicFormComponent.prototype.onCancelled = function () {
        this.onCancel.emit(null);
    };
    return DynamicFormComponent;
}());
export { DynamicFormComponent };
