import * as tslib_1 from "tslib";
import * as ControlType from 'app/shared/question-model/control-type';
import { QuestionBase } from './question-base';
var CheckboxQuestion = /** @class */ (function (_super) {
    tslib_1.__extends(CheckboxQuestion, _super);
    function CheckboxQuestion(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = ControlType.CHECKBOX;
        // WARN Do not bind radio type in template,
        // it should be explicitely type="radio" and not [type]="option.type"
        _this.type = 'checkbox';
        _this.help = options['help'] || '';
        return _this;
    }
    return CheckboxQuestion;
}(QuestionBase));
export { CheckboxQuestion };
