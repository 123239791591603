<form (ngSubmit)="onSubmit()" [formGroup]="form" novalidate>

  <div *ngFor="let question of questions">
    <xcm-df-question [question]="question" [form]="form"></xcm-df-question>
  </div>

  <div class="pull-right" *ngIf="questions.length>0; else noquestions">
      <button type="button" class="btn btn-outline-primary mb-2 mb-md-0 mr-2 test-cancel" (click)="onCancelled()" i18n="@@action.editcancel">Cancel</button>            
      <button type="submit" [disabled]="!form.valid" class="btn btn-primary mb-2 mb-md-0 mr-2 test-save" i18n="@@action.editsave">Save</button>
  </div>

  <ng-template #noquestions><p>Empty profile</p></ng-template>
</form>