/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./xcm-config.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../assetgroup/scope/scope.component.ngfactory";
import * as i3 from "../../assetgroup/scope/scope.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./xcm-config.component";
var styles_XcmConfigComponent = [i0.styles];
var RenderType_XcmConfigComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_XcmConfigComponent, data: {} });
export { RenderType_XcmConfigComponent as RenderType_XcmConfigComponent };
export function View_XcmConfigComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "xcm-scope", [], null, [[null, "onScopeSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onScopeSelected" === en)) {
        var pd_0 = (_co.onScopeSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ScopeComponent_0, i2.RenderType_ScopeComponent)), i1.ɵdid(2, 573440, null, 0, i3.ScopeComponent, [], { scopes: [0, "scopes"] }, { onScopeSelected: "onScopeSelected" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.scopes$)); _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_XcmConfigComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-xcm-config", [], null, null, null, View_XcmConfigComponent_0, RenderType_XcmConfigComponent)), i1.ɵdid(1, 114688, null, 0, i6.XcmConfigComponent, [i5.ActivatedRoute, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var XcmConfigComponentNgFactory = i1.ɵccf("xcm-xcm-config", i6.XcmConfigComponent, View_XcmConfigComponent_Host_0, {}, {}, []);
export { XcmConfigComponentNgFactory as XcmConfigComponentNgFactory };
