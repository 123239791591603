import * as tslib_1 from "tslib";
import * as ControlType from 'app/shared/question-model/control-type';
import { QuestionBase } from './question-base';
var MultiSelectQuestion = /** @class */ (function (_super) {
    tslib_1.__extends(MultiSelectQuestion, _super);
    function MultiSelectQuestion(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.options = [];
        if (!_this.value) {
            _this.value = [];
        }
        _this.options = options['options'] || [];
        _this.controlType = ControlType.MULTISELECT;
        return _this;
    }
    return MultiSelectQuestion;
}(QuestionBase));
export { MultiSelectQuestion };
