
import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {publishLast, refCount} from 'rxjs/operators';

import {MetagroupService} from '../../core/metagroup.service';

import {Metagroup} from './metagroup';

@Component({
  selector: 'xcm-metagroup',
  templateUrl: './metagroup.component.html',
  styleUrls: ['./metagroup.component.css']
})
export class MetagroupComponent implements OnChanges, OnDestroy {
  @Input() scope: string;
  @Output() onMetagroupSelected = new EventEmitter<Metagroup>();
  metagroups: Array<Metagroup>;
  selectedMetagroup: Metagroup;
  metagroups$: Observable<Metagroup[]>;
  subObject: Subscription;

  constructor(private metagroupService: MetagroupService) {}

  ngOnChanges() {
    if (this.scope) {
      this.metagroups$ = this.metagroupService.getMetagroups(this.scope)
                             .pipe(
                                 publishLast(),
                                 refCount(),
                             );
      this.subObject = this.metagroups$.subscribe((metagroups) => {
        this.metagroups = metagroups;
        if (this.metagroups.length === 1) {
          this.selectedMetagroup = this.metagroups[0];
          setTimeout(() => this.onMetagroupSelected.emit(this.selectedMetagroup), 0);
        }
      });
    }
  }

  onMetagroupChange() {
    if (this.selectedMetagroup) {
      this.onMetagroupSelected.emit(this.selectedMetagroup);
    }
  }

  ngOnDestroy() {
    if (this.subObject) {
      this.subObject.unsubscribe();
    }
  }
}
