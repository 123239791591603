import {ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeleteUsecase} from 'app/assetgroup/confirm-modal/delete-usecase';
import {AssetgroupService} from 'app/core/assetgroup.service';
import {FilterInputComponent} from 'app/shared/filter-input/filter-input.component';
import {Asset} from 'app/shared/models/asset';
import {AuthorizationData} from 'app/shared/models/authorization';
import {Observable} from 'rxjs';
import {concat, filter, map, publishLast, refCount, switchMap} from 'rxjs/operators';

@Component({
  selector: 'xcm-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AssetListComponent implements OnChanges {
  @Input() assetgroup: string;
  @ViewChild(FilterInputComponent) private filterinputComponent: FilterInputComponent;
  private reloadassets$: Observable<Asset[]>;
  authorizations$: Observable<AuthorizationData>;
  assets$: Observable<Asset[]>;
  selectedAsset: Asset;
  filteredAssets$: Observable<Asset[]>;
  filterCallback = this.assetgroupService.filterAssets;

  constructor(
      private assetgroupService: AssetgroupService, private route: ActivatedRoute,
      private router: Router) {}


  ngOnChanges() {
    if (this.assetgroup) {
      this.authorizations$ = this.route.data.pipe(map(data => data.authorizations));
      this.reloadassets$ = this.assetgroupService.getAssets(this.assetgroup);
      this.assets$ = this.reloadassets$.pipe(publishLast(), refCount());
      this.filteredAssets$ = this.assets$;
    }
  }


  onDelete(asset: Asset) {
    const assetId = asset.id;
    if (assetId) {
      const tmpassets$ = this.reloadassets$.pipe(publishLast(), refCount());
      this.assetgroupService.confirmDialog(DeleteUsecase.ASSET)
          .pipe(
              filter(value => value),
              switchMap(
                  () => this.assetgroupService.deleteAsset(this.assetgroup, assetId)
                            .pipe(concat(tmpassets$))))
          .subscribe(() => {}, () => {}, () => {
            this.assets$ = tmpassets$;
            this.filteredAssets$ = this.assets$;
            this.filterinputComponent.cleanup();
          });
    } else {
      console.log('asset.id is undefined');
    }
  }

  setfilteredAssets(assets: Observable<Asset[]>) {
    this.filteredAssets$ = assets;
  }

  onDetails(asset: Asset) {
    // this.selectedAsset = asset;
    this.router.navigate(['assets', asset.id], {relativeTo: this.route})
  }
}
