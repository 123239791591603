/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./asset-detail.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/dynamic-view/dynamic-view.component.ngfactory";
import * as i3 from "../../../shared/dynamic-view/dynamic-view.component";
import * as i4 from "@angular/common";
import * as i5 from "./asset-detail.component";
import * as i6 from "../../../core/assetprofile.service";
var styles_AssetDetailComponent = [i0.styles];
var RenderType_AssetDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssetDetailComponent, data: {} });
export { RenderType_AssetDetailComponent as RenderType_AssetDetailComponent };
function View_AssetDetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-dynamic-view", [], null, null, null, i2.View_DynamicViewComponent_0, i2.RenderType_DynamicViewComponent)), i1.ɵdid(1, 114688, null, 0, i3.DynamicViewComponent, [], { questions: [0, "questions"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.questions; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AssetDetailComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetDetailComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.asset; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AssetDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-asset-detail", [], null, null, null, View_AssetDetailComponent_0, RenderType_AssetDetailComponent)), i1.ɵdid(1, 573440, null, 0, i5.AssetDetailComponent, [i6.AssetprofileService], null, null)], null, null); }
var AssetDetailComponentNgFactory = i1.ɵccf("xcm-asset-detail", i5.AssetDetailComponent, View_AssetDetailComponent_Host_0, { asset: "asset" }, {}, []);
export { AssetDetailComponentNgFactory as AssetDetailComponentNgFactory };
