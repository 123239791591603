<ng-container *ngIf="profiles">
  <ul class="list-group">
    <li class="list-group-item"  *ngFor="let profile of profiles">
      <div class="row align-items-center">
        <span class="col-md-6">{{ getProfileInfo(profile) }}</span>
        <div class="col-md-6">
          <div class="pull-right">
            <ng-container *ngIf="showButton">
                <button type="button" (click)="delete(profile)" class="btn btn-outline-danger mb-2 mb-md-0 mr-2 test-delete-assetprofile"><i class="fa fa-trash-o fa-lg fa-fw" aria-hidden="true" i18n></i>Delete</button>
                <button type="button" (click)="onDetails(profile)" class="btn btn-outline-primary mb-2 mb-md-0 mr-2 test-details-assetprofile"><i class="fa fa-eye fa-lg fa-fw" aria-hidden="true" i18n></i>Details</button>
            </ng-container>
          </div>
        </div>
      </div>     
    </li>
  </ul>
  <ng-container *ngIf="profiles.length === 0"> No Asset Profiles Found for {{ scope }}</ng-container>
</ng-container>
