
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {Metagroup} from 'app/assetgroup/metagroup/metagroup';
import {ChangeMetagroup} from 'app/reducers/metagroup.actions';
import {AppState} from 'app/reducers/reducers';
import {EMPTY, Observable, of} from 'rxjs';

@Injectable()
export class MetagroupResolver {
  constructor(private store: Store<AppState>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Metagroup> {
    let res: Observable<Metagroup> = EMPTY;
    const metagroupId = route.paramMap.get('id') || '';
    if (route.parent && route.parent.data.metagroups) {
      const selectedMetagroup = (<Array<Metagroup>>route.parent.data.metagroups)
                                    .find(metagroup => metagroup.id === metagroupId);
      if (selectedMetagroup) {
        res = of(selectedMetagroup);
        // update the store
        this.store.dispatch(new ChangeMetagroup(selectedMetagroup));
      }
    }
    return res;
  }
}
