import * as tslib_1 from "tslib";
import * as ControlType from 'app/shared/question-model/control-type';
import { SingleSelectQuestion } from './question-single-select';
var RadiosQuestion = /** @class */ (function (_super) {
    tslib_1.__extends(RadiosQuestion, _super);
    function RadiosQuestion(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = ControlType.RADIOS;
        _this.type = 'radio';
        return _this;
        // WARN Do not bind radio type in template, it should be explicitely type="radio" and not
        // [type]="option.type"
    }
    return RadiosQuestion;
}(SingleSelectQuestion));
export { RadiosQuestion };
