import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {AssetprofileService} from 'app/core/assetprofile.service';
import {AssetProfile} from 'app/shared/models/asset';
import {Observable} from 'rxjs';

@Injectable()
export class AssetProfilesResolver implements Resolve<AssetProfile[]> {
  constructor(private assetprofileService: AssetprofileService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<AssetProfile[]> {
    const metagroupid = route.paramMap.get('id') || '-1';
    const scope = route.paramMap.get('scope') || '';
    return this.assetprofileService.getAssetProfilesByScopeAndMetagroup(scope, metagroupid);
  }
}
