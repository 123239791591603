import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {QuestionBase} from 'app/shared/question-model/question-base';

import {QuestionControlService} from './question-control.service';

@Component({
  selector: 'xcm-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnChanges {
  private sortedquestions: QuestionBase<any>[];
  @Input()
  set questions(questions: QuestionBase<any>[]) {
    this.sortedquestions = questions.sort((q1, q2) => +q1.order - +q2.order);
  }

  get questions() {
    return this.sortedquestions;
  }

  @Output() onSave = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();
  form: FormGroup;

  constructor(private questionControlService: QuestionControlService) {}

  ngOnChanges() {
    this.form = this.questionControlService.toFormGroup(this.questions);
  }

  onSubmit() {
    this.onSave.emit(this.form.value);
  }

  onCancelled() {
    this.onCancel.emit(null);
  }
}
