
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorHandlerService} from 'app/core/error-hanlder.service';
import {Scope} from 'app/shared/models/scope';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

interface ScopeResponse {
  data: Scope[];
}

@Injectable()
export class ScopeService {
  private readonly apiUrl: string = '/v1/xcm/scopes';
  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {}

  getScopes(): Observable<Scope[]> {
    return this.http.get<ScopeResponse>(`${this.apiUrl}`)
        .pipe(map((res) => res.data), catchError(this.errorHandlerService.handleError));
  }
}
