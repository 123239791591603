import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Injectable()
export class ValidationService {
  constructor() {}

  showSuccessFeedback(control: AbstractControl|null) {
    return !!control && control.dirty && control.valid;
  }

  showErrorFeedback(control: AbstractControl|null, errorname: string) {
    return !!control && (control.dirty || control.touched) && control.hasError(errorname);
  }

  showGlobalErrorFeedback(control: AbstractControl|null) {
    return !!control && (control.dirty || control.touched) && !control.valid;
  }
}
