import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {AssetprofileService} from 'app/core/assetprofile.service';
import {Asset} from 'app/shared/models/asset';
import {QuestionBase} from 'app/shared/question-model/question-base';

@Component({
  selector: 'xcm-asset-detail',
  templateUrl: './asset-detail.component.html',
  styleUrls: ['./asset-detail.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetDetailComponent implements OnChanges {
  @Input() asset: Asset;
  questions: QuestionBase<any>[];
  constructor(private assetprofileService: AssetprofileService) {}

  ngOnChanges() {
    if (this.asset) {
      this.questions = this.assetprofileService.getAssetQuestions(this.asset);
    }
  }
}
