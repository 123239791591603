/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./confirm-modal.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_ConfirmModalComponent = [i0.styles];
var RenderType_ConfirmModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmModalComponent, data: {} });
export { RenderType_ConfirmModalComponent as RenderType_ConfirmModalComponent };
function View_ConfirmModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete Assetgroup"]))], null, null); }
function View_ConfirmModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete Asset"]))], null, null); }
function View_ConfirmModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unlink Assetgroup"]))], null, null); }
function View_ConfirmModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], null, null); }
function View_ConfirmModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are about to delete the assetgroup along with its assets."])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure you want to delete the selected assetgroup?"]))], null, null); }
function View_ConfirmModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are about to remove asset from the assetgroup. "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure you want to remove the selected asset?"]))], null, null); }
function View_ConfirmModalComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are about to unlink assetgroup. "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Users will not be able to benefit from assetgroup anymore. "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Are you sure you want to unlink selected items?"]))], null, null); }
function View_ConfirmModalComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm Deletion"]))], null, null); }
export function View_ConfirmModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_3)), i1.ɵdid(9, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(15, 0, null, null, 11, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 10, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 9, null, null, null, null, null, null, null)), i1.ɵdid(18, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_5)), i1.ɵdid(20, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_6)), i1.ɵdid(22, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_7)), i1.ɵdid(24, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmModalComponent_8)), i1.ɵdid(26, 16384, null, 0, i2.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], null, null), (_l()(), i1.ɵeld(27, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "button", [["class", "btn btn-outline-danger mb-2 mb-md-0 mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes"])), (_l()(), i1.ɵeld(30, 0, null, null, 1, "button", [["class", "btn btn-outline-dark mb-2 mb-md-0 mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.usecase; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.deleteAssetgroup; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.deleteAsset; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.unlinkAssetgroup; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.usecase; _ck(_v, 18, 0, currVal_4); var currVal_5 = _co.deleteAssetgroup; _ck(_v, 20, 0, currVal_5); var currVal_6 = _co.deleteAsset; _ck(_v, 22, 0, currVal_6); var currVal_7 = _co.unlinkAssetgroup; _ck(_v, 24, 0, currVal_7); }, null); }
export function View_ConfirmModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-confirm-modal", [], null, null, null, View_ConfirmModalComponent_0, RenderType_ConfirmModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.ConfirmModalComponent, [i4.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmModalComponentNgFactory = i1.ɵccf("xcm-confirm-modal", i3.ConfirmModalComponent, View_ConfirmModalComponent_Host_0, { usecase: "usecase" }, {}, []);
export { ConfirmModalComponentNgFactory as ConfirmModalComponentNgFactory };
