import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ValidationService} from 'app/core/validation.service';

@Component({
  selector: 'xcm-textbox-profile',
  templateUrl: './textbox-profile.component.html',
  styleUrls: ['./textbox-profile.component.css']
})
export class TextboxProfileComponent implements OnInit {
  @Input() form: FormGroup;
  constructor(private validationService: ValidationService) {}

  ngOnInit() {}

  showSuccessFeedback(controlname: string) {
    return this.validationService.showSuccessFeedback(this.form.get(controlname));
  }

  showErrorFeedback(controlname: string, errorname: string) {
    return this.validationService.showErrorFeedback(this.form.get(controlname), errorname);
  }

  showGlobalErrorFeedback(controlname: string) {
    return this.validationService.showGlobalErrorFeedback(this.form.get(controlname));
  }
}
