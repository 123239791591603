
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {DeleteUsecase} from 'app/assetgroup/confirm-modal/delete-usecase';
import {Metagroup} from 'app/assetgroup/metagroup/metagroup';
import {AppState} from 'app/reducers/reducers';
import {ResetScope} from 'app/reducers/scope.actions';
import {AuthorizationData} from 'app/shared/models/authorization';
import {Scope} from 'app/shared/models/scope';
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';
import {concat, filter, map, publishLast, publishReplay, refCount, switchMap} from 'rxjs/operators';

import {AssetgroupService} from '../../core/assetgroup.service';

import {Assetgroup} from './assetgroup';

@Component({
  selector: 'xcm-assetgroup-list',
  templateUrl: './assetgroup-list.component.html',
  styleUrls: ['./assetgroup-list.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AssetgroupListComponent implements OnInit {
  authorizations$: Observable<AuthorizationData>;
  combined$: Observable<[Scope, Metagroup]>;
  scope$: Observable<Scope>;
  metagroup$: Observable<Metagroup>;
  assetgroup: Assetgroup;
  displayform: boolean;
  private reloadassetgroups$: Observable<Assetgroup[]>;
  // @ViewChild(FilterInputComponent) private filterinputComponent: FilterInputComponent;
  assetgroups$: Observable<Assetgroup[]>;
  filteredAssetgroups$: Observable<Assetgroup[]>;
  selectedAssetgroup: Assetgroup|undefined;
  filterCallback = this.assetgroupService.filterAssetgroups;
  constructor(
      private assetgroupService: AssetgroupService, private router: Router,
      private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit() {
    this.authorizations$ = this.route.data.pipe(map(data => data.authorizations));
    this.scope$ = this.store.select('scope').pipe(
        filter((scopes) => !!scopes && scopes.length > 0), map((scopes) => scopes[0]));
    this.metagroup$ = this.store.pipe(select('metagroup'));
    this.combined$ = observableCombineLatest(this.scope$, this.metagroup$);
    this.combined$.subscribe((values) => {
      const [scope, metagroup] = values;
      if (scope && metagroup && metagroup.id) {
        this.reloadassetgroups$ = this.assetgroupService.getAssetgroups(scope.label, metagroup.id);
        this.assetgroups$ = this.reloadassetgroups$.pipe(publishLast(), refCount());
        this.filteredAssetgroups$ = this.assetgroups$;
        this.assetgroup = new Assetgroup();
        this.assetgroup.metagroup.id = metagroup.id;
        this.assetgroup.scope = scope.label;
      } else {
        this.router.navigate(['/']);
      }
    })
  }


  onDelete(assetgroup: Assetgroup) {
    const assetgroupId = assetgroup.id;
    if (assetgroupId) {
      // need to pass through a temporary observable to avoid several calls
      const temporary$ = this.reloadassetgroups$.pipe(
          publishLast(),
          refCount(),
      );
      this.assetgroupService.confirmDialog(DeleteUsecase.ASSETGROUP)
          .pipe(
              filter(value => value),
              switchMap(
                  () => this.assetgroupService.deleteAssetgroup(assetgroupId)
                            .pipe(concat(temporary$))))
          .subscribe(() => {}, () => {}, () => {
            this.assetgroups$ = temporary$;
            this.filteredAssetgroups$ = this.assetgroups$;
          });
    }
  }

  onDetails(assetgroup: Assetgroup) {
    this.selectedAssetgroup = assetgroup;
    this.router.navigate([assetgroup.id], {relativeTo: this.route});
  }

  onCancel() {
    this.exitForm();
  }

  exitForm() {
    this.displayform = false;
  }

  showForm() {
    this.displayform = true;
  }

  setFilteredAssetgroups(assetgroups: Observable<Assetgroup[]>) {
    this.filteredAssetgroups$ = assetgroups;
  }

  addAssetgroup(assetgroup: Assetgroup) {
    const assetgroups$ = this.reloadassetgroups$.pipe(
        publishReplay(),
        refCount(),
    );
    this.assetgroupService.addAssetgroup(assetgroup).pipe(concat(assetgroups$)).subscribe(() => {
      this.assetgroups$ = assetgroups$;
      this.filteredAssetgroups$ = this.assetgroups$;
      this.exitForm();
    });
  }

  reset() {
    this.store.dispatch(new ResetScope());
    this.router.navigate(['/']);
  }
}
