import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AssetgroupListComponent} from 'app/assetgroup/assetgroup-list/assetgroup-list.component';
import {AssetgroupResolver} from 'app/assetgroup/assetgroup-resolver.service';
import {AssetgroupViewComponent} from 'app/assetgroup/assetgroup-view/assetgroup-view.component';
import {AssetViewComponent} from 'app/assetgroup/assets/asset-view/asset-view.component';
import {AuthorizationsResolver} from 'app/assetgroup/authorizations-resolver.service';
import {MetagroupResolver} from 'app/assetgroup/metagroup/metagroup-resolver.service';
import {MetagroupsResolver} from 'app/assetgroup/metagroup/metagroups-resolver.service';
import {ScopeResolver} from 'app/assetgroup/scope/scope-resolver.service';
import {ScopesResolver} from 'app/assetgroup/scope/scopes-resolver.service';

import {AuthenticationGuardService} from '../core/authentication-guard.service';

import {AssetgroupComponent} from './assetgroup.component';

const contextSetupRoutes: Routes = [{
  path: '',
  resolve: {grantedScopes: ScopesResolver},
  canActivateChild: [AuthenticationGuardService],
  children: [
    {
      path: 'scope/:scope',
      resolve: {metagroups: MetagroupsResolver, scope: ScopeResolver},
      children: [{
        path: 'metagroup/:id',
        resolve: {metagroup: MetagroupResolver, authorizations: AuthorizationsResolver},
        children: [
          {
            path: 'assetgroups/:id/assets/:assetId',
            component: AssetViewComponent,
          },
          {
            path: 'assetgroups/:id',
            resolve: {assetgroup: AssetgroupResolver},
            component: AssetgroupViewComponent,
          },
          {
            path: 'assetgroups',
            component: AssetgroupListComponent,
          },
          {path: '', redirectTo: 'assetgroups', pathMatch: 'full'}
        ],
      }]
    },
    {path: '', component: AssetgroupComponent}
  ]
}

];

@NgModule({
  imports: [RouterModule.forChild(contextSetupRoutes)],
  exports: [RouterModule],
  providers: [AssetgroupResolver, ScopeResolver, MetagroupResolver, AuthorizationsResolver]
})
export class AssetgroupRoutingModule {
}
