/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./asset-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/filter-input/filter-input.component.ngfactory";
import * as i3 from "../../../shared/filter-input/filter-input.component";
import * as i4 from "@angular/common";
import * as i5 from "./asset-list.component";
import * as i6 from "../../../core/assetgroup.service";
import * as i7 from "@angular/router";
var styles_AssetListComponent = [i0.styles];
var RenderType_AssetListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssetListComponent, data: {} });
export { RenderType_AssetListComponent as RenderType_AssetListComponent };
function View_AssetListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-filter-input", [], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.setfilteredAssets($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FilterInputComponent_0, i2.RenderType_FilterInputComponent)), i1.ɵdid(1, 704512, [[1, 4]], 0, i3.FilterInputComponent, [], { collection: [0, "collection"], filtercallback: [1, "filtercallback"] }, { onChange: "onChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.ngIf; var currVal_1 = _co.filterCallback; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AssetListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, null); }
function View_AssetListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-outline-danger mb-2 mb-md-0 mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-trash-o fa-lg fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], null, null); }
function View_AssetListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-outline-primary mb-2 mb-md-0 mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDetails(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-eye fa-lg fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Details"]))], null, null); }
function View_AssetListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "col-md-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetListComponent_6)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetListComponent_7)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_1_0 = null; var currVal_1 = (((tmp_1_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.authorizations$))) == null) ? null : tmp_1_0.D_ASSET); _ck(_v, 7, 0, currVal_1); var tmp_2_0 = null; var currVal_2 = (((tmp_2_0 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.authorizations$))) == null) ? null : tmp_2_0.R_ASSET); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.profile.label; _ck(_v, 3, 0, currVal_0); }); }
function View_AssetListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetListComponent_5)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 3, 0, currVal_0); }, null); }
function View_AssetListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetListComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetListComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetListComponent_4)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.ngIf.length > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.ngIf.length > 0); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.filteredAssets$)); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_AssetListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { filterinputComponent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetListComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.assets$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AssetListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-asset-list", [], null, null, null, View_AssetListComponent_0, RenderType_AssetListComponent)), i1.ɵdid(1, 573440, null, 0, i5.AssetListComponent, [i6.AssetgroupService, i7.ActivatedRoute, i7.Router], null, null)], null, null); }
var AssetListComponentNgFactory = i1.ɵccf("xcm-asset-list", i5.AssetListComponent, View_AssetListComponent_Host_0, { assetgroup: "assetgroup" }, {}, []);
export { AssetListComponentNgFactory as AssetListComponentNgFactory };
