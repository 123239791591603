import {Action} from '@ngrx/store';
import {Metagroup} from 'app/assetgroup/metagroup/metagroup';

export const CHANGE_CUSTOMER = 'CHANGE_CUSTOMER';

export class ChangeMetagroup implements Action {
  readonly type = CHANGE_CUSTOMER;
  constructor(public payload: Metagroup) {}
}

export type All = ChangeMetagroup;
