import {Component, Input, OnInit} from '@angular/core';
import {QuestionBase} from 'app/shared/question-model/question-base';
import {DropdownQuestion} from 'app/shared/question-model/question-dropdown';
import {MultiSelectQuestion} from 'app/shared/question-model/question-multi-select';
import {RadiosQuestion} from 'app/shared/question-model/question-radios';
import {TextboxQuestion} from 'app/shared/question-model/question-textbox';


@Component({
  selector: 'xcm-dynamic-view-question',
  templateUrl: './dynamic-view-question.component.html',
  styleUrls: ['./dynamic-view-question.component.css']
})
export class DynamicViewQuestionComponent implements OnInit {
  private static readonly concealedValue = '**********';
  @Input() question: QuestionBase<any>;
  constructor() {}

  ngOnInit() {}

  getOptionLabel(optionId: string) {
    if (this.question instanceof DropdownQuestion || this.question instanceof RadiosQuestion ||
        this.question instanceof MultiSelectQuestion) {
      const opt = this.question.options.find((option: any) => option.id === optionId);
      if (opt) {
        return opt.value;
      }
    }
  }

  getQuestionValue() {
    return (this.question instanceof TextboxQuestion && this.question.type === 'password') ?
        DynamicViewQuestionComponent.concealedValue :
        (this.question.value || '-');
  }
}
