import * as ControlType from 'app/shared/question-model/control-type';

import {SingleSelectQuestion} from './question-single-select';

export class DropdownQuestion extends SingleSelectQuestion {
  constructor(options: {} = {}) {
    super(options);
    this.controlType = ControlType.DROPDOWN;
    ;
    // WARN Do not bind radio type in template, it should be explicitely type="radio" and not
    // [type]="option.type"
  }
}
