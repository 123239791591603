import * as tslib_1 from "tslib";
import * as ControlType from 'app/shared/question-model/control-type';
import { QuestionBase } from './question-base';
var TextboxQuestion = /** @class */ (function (_super) {
    tslib_1.__extends(TextboxQuestion, _super);
    function TextboxQuestion(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = ControlType.TEXTBOX;
        _this.type = options['type'] || 'text';
        _this.placeholder = options['placeholder'] || '';
        _this.description = options['description'] || '';
        _this.regex = options['regex'] || '';
        return _this;
    }
    return TextboxQuestion;
}(QuestionBase));
export { TextboxQuestion };
