<ng-container *ngIf="members$ | async as members">
  <div *ngIf="members.length > 0; else noMembersLinked" [style.cursor]="processing ? 'wait' : 'pointer'">
  <xcm-filter-input [collection]="members" [filtercallback]="filterCallback" (onChange)="setFilteredEntities($event)"></xcm-filter-input>
    <div class="form-group row" style="margin-top: 10px; margin-left: auto;">
      <div ngbDropdown class="d-inline-block">
        <div class="dropdown-label">Sort:</div>
            <select name="sortMenu" ngbDropdown class="btn btn-outline-primary" [(ngModel)]="sortAscending" (change)="onSort()" [ngModelOptions]="{standalone: true}">
              <option disabled>Select sort order</option>  
              <option [ngValue]="true">Ascending (a-z)</option>
              <option [ngValue]="false">Descending (z-a)</option>
            </select>
        </div>
      </div>
    <hr>
    <ng-container *ngIf="filteredMembers$ | async as filteredMembers">
      <ul style="margin-top: 10px;" class="list-group">
        <li class="list-group-item" *ngFor="let member of filteredMembers">
          <div class="row align-items-center">
            <span class="col-md-4"> {{ member.label }} </span>            
            <div class="col-md-8">
              <div class="pull-right">
                <button *ngIf="(authorizations$ | async)?.U_AG_ENTITIES; else readonlydefaultstatus" (click)="onDefault(member)" class="btn" [class.btn-success]="member.default"><i class="fa fa-lg fa-check fa-fw" aria-hidden="true"></i><span i18n>Default</span></button>        
                <ng-template #readonlydefaultstatus>
                  <span class="btn" [class.btn-success]="member.default">
                    <i [class.fa-check]="member.default" class="fa fa-lg fa-fw" aria-hidden="true"></i>
                    <ng-container *ngIf="member.default" i18n>Default</ng-container>
                  </span>
                </ng-template>
                <button *ngIf="(authorizations$ | async)?.U_AG_ENTITIES"
                  [disabled]="processing"
                  (click)="onUnlink(member)"
                  class="btn btn-outline-danger mb-2 mb-md-0 mr-2 test-unlink-entity pull-right"
                  style="margin-bottom: 5px;"
                  i18n>
                  <i class="fa fa-unlink fa-lg fa-fw" aria-hidden="true"></i>
                    <span>&nbsp;Unlink</span>
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </ng-container>
    <hr  *ngIf="members.length>0">
    <div style="margin-top: 20px;">
      <button *ngIf="(authorizations$ | async)?.U_AG_ENTITIES"
        [disabled]="processing"
        (click)="onUnlinkAll()"
        class="btn btn-outline-danger mb-2 mb-md-0 mr-2 test-unlink-entity pull-right"
        style="margin-bottom: 5px;"
        i18n>
          <i *ngIf="!processing; else doprocessing" class="fa fa-unlink fa-lg fa-fw" aria-hidden="true"></i>
            <ng-template #doprocessing><i class="fa fa-spinner fa-spin fa-fw"></i></ng-template>
            <span>&nbsp;Unlink All</span>
        </button>
    </div>
  </div>
</ng-container>
<ng-template #noMembersLinked>
  <label style="margin-top: 10px; color: #808080;" i18n><em>No Entities linked.</em></label>
</ng-template>
