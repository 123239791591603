import * as tslib_1 from "tslib";
import { QuestionBase } from './question-base';
var SingleSelectQuestion = /** @class */ (function (_super) {
    tslib_1.__extends(SingleSelectQuestion, _super);
    function SingleSelectQuestion(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.options = [];
        _this.options = options['options'] || [];
        return _this;
    }
    return SingleSelectQuestion;
}(QuestionBase));
export { SingleSelectQuestion };
