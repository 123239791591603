/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./metagroup-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./metagroup-list.component";
var styles_MetagroupListComponent = [i0.styles];
var RenderType_MetagroupListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MetagroupListComponent, data: {} });
export { RenderType_MetagroupListComponent as RenderType_MetagroupListComponent };
function View_MetagroupListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", " ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.details(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Details"]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.label); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.organization); var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.application); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_MetagroupListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MetagroupListComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metagroups; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MetagroupListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-metagroup-list", [], null, null, null, View_MetagroupListComponent_0, RenderType_MetagroupListComponent)), i1.ɵdid(1, 114688, null, 0, i3.MetagroupListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MetagroupListComponentNgFactory = i1.ɵccf("xcm-metagroup-list", i3.MetagroupListComponent, View_MetagroupListComponent_Host_0, { metagroups: "metagroups" }, { onDetails: "onDetails" }, []);
export { MetagroupListComponentNgFactory as MetagroupListComponentNgFactory };
