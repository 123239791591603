/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dynamic-view-question.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./dynamic-view-question.component";
var styles_DynamicViewQuestionComponent = [i0.styles];
var RenderType_DynamicViewQuestionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DynamicViewQuestionComponent, data: {} });
export { RenderType_DynamicViewQuestionComponent as RenderType_DynamicViewQuestionComponent };
function View_DynamicViewQuestionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "info-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.question.label; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getQuestionValue(); _ck(_v, 4, 0, currVal_1); }); }
function View_DynamicViewQuestionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [["class", "badge badge-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ACTIVATED"]))], null, null); }
function View_DynamicViewQuestionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [["class", "badge badge-default"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["DEACTIVATED"]))], null, null); }
function View_DynamicViewQuestionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "info-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicViewQuestionComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["deactivated", 2]], null, 0, null, View_DynamicViewQuestionComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.question.value; var currVal_2 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.question.label; _ck(_v, 2, 0, currVal_0); }); }
function View_DynamicViewQuestionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "info-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.question.label; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getOptionLabel(_co.question.value); _ck(_v, 4, 0, currVal_1); }); }
function View_DynamicViewQuestionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "info-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.question.label; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getOptionLabel(_co.question.value); _ck(_v, 4, 0, currVal_1); }); }
function View_DynamicViewQuestionComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getOptionLabel(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_DynamicViewQuestionComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "info-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [["class", "list-unstyled"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicViewQuestionComponent_8)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.question.value; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.question.label; _ck(_v, 2, 0, currVal_0); }); }
export function View_DynamicViewQuestionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicViewQuestionComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicViewQuestionComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicViewQuestionComponent_5)), i1.ɵdid(7, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicViewQuestionComponent_6)), i1.ɵdid(9, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicViewQuestionComponent_7)), i1.ɵdid(11, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.question.controlType; _ck(_v, 1, 0, currVal_0); var currVal_1 = "TEXTBOX"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "CHECKBOX"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "RADIOS"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "DROPDOWN"; _ck(_v, 9, 0, currVal_4); var currVal_5 = "MULTISELECT"; _ck(_v, 11, 0, currVal_5); }, null); }
export function View_DynamicViewQuestionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-dynamic-view-question", [], null, null, null, View_DynamicViewQuestionComponent_0, RenderType_DynamicViewQuestionComponent)), i1.ɵdid(1, 114688, null, 0, i3.DynamicViewQuestionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DynamicViewQuestionComponentNgFactory = i1.ɵccf("xcm-dynamic-view-question", i3.DynamicViewQuestionComponent, View_DynamicViewQuestionComponent_Host_0, { question: "question" }, {}, []);
export { DynamicViewQuestionComponentNgFactory as DynamicViewQuestionComponentNgFactory };
