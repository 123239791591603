
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Metagroup} from 'app/assetgroup/metagroup/metagroup';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'xcm-metagroups-overview',
  templateUrl: './metagroups-overview.component.html',
  styleUrls: ['./metagroups-overview.component.css']
})
export class MetagroupsOverviewComponent implements OnInit {
  metagroups$: Observable<Metagroup[]>;
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.metagroups$ = this.route.data.pipe(map(data => data.metagroups));
  }

  details(metagroup: Metagroup) {
    this.router.navigate([metagroup.id], {relativeTo: this.route});
  }
}
