<div [formGroup]="form">
    <div class="form-group row">
      <label for="id" class="col-2 col-form-label" i18n="@@textbox.id">Identifier</label>
      <div class="col-10">
        <input formControlName="id" id="id" type="text"
        class="form-control"
        [class.is-valid]="showSuccessFeedback('id')"
        [class.is-invalid]="showGlobalErrorFeedback('id')"
        placeholder="identifier"
        aria-describedby="helpid"/>
        <div *ngIf="showErrorFeedback('id', 'required')" class="invalid-feedback">Identifier is required</div>                
        <div *ngIf="showErrorFeedback('id', 'pattern')" class="invalid-feedback">Identifier should start with a letter and can only contains alphanumeric characters</div>      </div>
    </div>

    <div class="form-group row">
      <label for="label" class="col-sm-2 col-form-label" i18n="@@textbox.label">Label</label>
      <div class="col-10">
        <input formControlName="label" id="label" type="text"
        class="form-control"
        [class.is-valid]="showSuccessFeedback('label')"
        [class.is-invalid]="showGlobalErrorFeedback('label')"
        placeholder="label"
        aria-describedby="helplabel"/>
        <div *ngIf="showErrorFeedback('label', 'required')" class="invalid-feedback">Label is required</div>        
      </div>
    </div>

    <div class="form-group row">
      <label for="default" class="col-sm-2 col-form-label" i18n="@@textbox.default">Default Value</label>
      <div class="col-10">
        <input formControlName="default" id="default" type="text"
        class="form-control"
        placeholder="Default Value"
        aria-describedby="helpdefault"/>
      </div>
    </div>

    <div class="form-group row">
      <label for="regex" class="col-sm-2 col-form-label" i18n="@@textbox.regex">Regular Expression</label>
      <div class="col-10">
        <input formControlName="regex" id="regex" type="text"
        class="form-control"
        [class.is-valid]="showSuccessFeedback('regex')"
        [class.is-invalid]="showGlobalErrorFeedback('regex')"
        placeholder="regex"
        aria-describedby="helpregex"/>
        <div *ngIf="showErrorFeedback('regex', 'regexformat')" class="invalid-feedback">Invalid regex pattern</div>                
      </div>
    </div>

    <div class="form-group row">
      <label for="order" class="col-sm-2 col-form-label" i18n="@@textbox.order">Order</label>
      <div class="col-10">
        <input formControlName="order" id="order" type="number"
        class="form-control"
        placeholder="order"
        aria-describedby="helporder"/>
      </div>
    </div>

    <div class="form-group row">
      <label for="description" class="col-sm-2 col-form-label" i18n="@@textbox.description">Description</label>
      <div class="col-10">
        <input formControlName="description" id="description" type="text"
        class="form-control"
        placeholder="description"
        aria-describedby="helpdescription"/>
      </div>
    </div>

    <div class="form-group row">
      <label for="required" class="col-sm-2 col-form-label" i18n="@@textbox.required">Required</label>  
      <div class="col-10 form-check">
          <label class="form-check-label form-check-nopadding">
            <input formControlName="required" id="required" type="checkbox" 
            class="form-check-input">
            <span>Required</span>
          </label>
      </div>
    </div>

    <div class="form-group row">
      <label for="concealed" class="col-sm-2 col-form-label" i18n="@@textbox.concealed">Concealed</label>
      <div class="col-10 form-check">
          <label class="form-check-label form-check-nopadding">
            <input formControlName="concealed" id="concealed" type="checkbox" 
            class="form-check-input">
            <span>Concealed</span>
          </label>
      </div>
    </div>
  </div>