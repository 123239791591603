/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header-nav/header-nav.component.ngfactory";
import * as i3 from "./header-nav/header-nav.component";
import * as i4 from "@ngrx/store";
import * as i5 from "./login/authentication.service";
import * as i6 from "./core/authorization.service";
import * as i7 from "@angular/router";
import * as i8 from "../../node_modules/ngx-progressbar/index.ngfactory";
import * as i9 from "ngx-progressbar";
import * as i10 from "./shared/alert/alert.component.ngfactory";
import * as i11 from "./shared/alert/alert.component";
import * as i12 from "./core/alert.service";
import * as i13 from "./footer/footer.component.ngfactory";
import * as i14 from "./footer/footer.component";
import * as i15 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "xcm-content design-factory-v2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "xcm-header-nav", [], null, null, null, i2.View_HeaderNavComponent_0, i2.RenderType_HeaderNavComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderNavComponent, [i4.Store, i5.AuthenticationService, i6.AuthorizationService, i7.Router], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ng-progress", [], null, null, null, i8.View_ɵa_0, i8.RenderType_ɵa)), i1.ɵdid(5, 573440, null, 0, i9.ɵa, [i9.NgProgress], { ease: [0, "ease"], showSpinner: [1, "showSpinner"], color: [2, "color"], thick: [3, "thick"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "xcm-alert", [], null, null, null, i10.View_AlertComponent_0, i10.RenderType_AlertComponent)), i1.ɵdid(8, 245760, null, 0, i11.AlertComponent, [i12.AlertService], { timeout: [0, "timeout"] }, null), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "xcm-footer", [], null, null, null, i13.View_FooterComponent_0, i13.RenderType_FooterComponent)), i1.ɵdid(12, 114688, null, 0, i14.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_0 = "linear"; var currVal_1 = false; var currVal_2 = "#E33939"; var currVal_3 = false; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = 10000; _ck(_v, 8, 0, currVal_4); _ck(_v, 10, 0); _ck(_v, 12, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i15.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("xcm-root", i15.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
