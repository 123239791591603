import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AssetgroupDetailComponent} from 'app/assetgroup/assetgroup-detail/assetgroup-detail.component';
import {AssetgroupFormComponent} from 'app/assetgroup/assetgroup-form/assetgroup-form.component';
import {AssetgroupHeaderComponent} from 'app/assetgroup/assetgroup-header/assetgroup-header.component';
import {AssetgroupListComponent} from 'app/assetgroup/assetgroup-list/assetgroup-list.component';
import {AssetgroupRoutingModule} from 'app/assetgroup/assetgroup-routing.module';
import {AssetgroupViewComponent} from 'app/assetgroup/assetgroup-view/assetgroup-view.component';
import {AssetgroupComponent} from 'app/assetgroup/assetgroup.component';
import {AssetCreateComponent} from 'app/assetgroup/assets/asset-create/asset-create.component';
import {AssetDetailComponent} from 'app/assetgroup/assets/asset-detail/asset-detail.component';
import {AssetEditComponent} from 'app/assetgroup/assets/asset-edit/asset-edit.component';
import {AssetListComponent} from 'app/assetgroup/assets/asset-list/asset-list.component';
import {AssetViewComponent} from 'app/assetgroup/assets/asset-view/asset-view.component';
import {ConfirmModalComponent} from 'app/assetgroup/confirm-modal/confirm-modal.component';
import {MembersComponent} from 'app/assetgroup/members/members.component';
import {UnlinkedMembersComponent} from 'app/assetgroup/members/unlinked-members.component';
import {MetagroupComponent} from 'app/assetgroup/metagroup/metagroup.component';
import {SharedModule} from 'app/shared/shared.module';
import {TreeviewModule} from 'ngx-treeview';
import {TreeviewComponent} from './treeview/treeview.component';

@NgModule({
  imports: [NgbModule, TreeviewModule, SharedModule, AssetgroupRoutingModule],
  declarations: [
    ConfirmModalComponent, MetagroupComponent, AssetgroupComponent, AssetgroupListComponent,
    AssetgroupDetailComponent, AssetListComponent, MembersComponent, UnlinkedMembersComponent,
    AssetDetailComponent, AssetViewComponent, AssetCreateComponent, AssetgroupFormComponent,
    AssetgroupViewComponent, AssetEditComponent, AssetgroupHeaderComponent, TreeviewComponent
  ],
  entryComponents: [ConfirmModalComponent],

})
export class AssetgroupModule {
}
