import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AssetProfile} from 'app/shared/models/asset';

@Component({
  selector: 'xcm-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileListComponent implements OnChanges {
  @Input() showButton = true;
  @Input() profiles: AssetProfile[];
  @Output() onDelete: EventEmitter<AssetProfile> = new EventEmitter();
  @Output() onEdit: EventEmitter<AssetProfile> = new EventEmitter();

  constructor() {}

  ngOnChanges() {}

  getProfileInfo(profile: AssetProfile) {
    return profile.label ||
        `${profile.externalSystem.system}` +
        (profile.externalSystem.subsystem ? ` - ${profile.externalSystem.subsystem}` : '');
  }

  delete(profile: AssetProfile) {
    this.onDelete.emit(profile);
  }

  onDetails(profile: AssetProfile) {
    this.onEdit.emit(profile);
  }
}
