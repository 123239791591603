
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {AssetprofileService} from 'app/core/assetprofile.service';
import {AssetProfile} from 'app/shared/models/asset';
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Component({
  selector: 'xcm-metagroup-profile',
  templateUrl: './metagroup-profile.component.html',
  styleUrls: ['./metagroup-profile.component.css']
})
export class MetagroupProfileComponent implements OnInit {
  profiles$: Observable<AssetProfile[]>;
  profilesToLink$: Observable<AssetProfile[]>;
  private allprofiles$: Observable<AssetProfile[]>;
  constructor(private route: ActivatedRoute, private assetprofileService: AssetprofileService) {}

  ngOnInit() {
    this.profiles$ = this.route.data.pipe(map(data => data.profiles));
    this.allprofiles$ = this.route.paramMap.pipe(switchMap(
        (params: ParamMap) =>
            this.assetprofileService.getAssetProfilesByScope(params.get('scope') || '')));

    this.profilesToLink$ =
        observableCombineLatest(this.profiles$, this.allprofiles$).pipe(map((data) => {
          const [profiles, allprofiles] = data;
          return allprofiles.filter((profile) => !profiles.some((item) => item.id === profile.id));
        }));
  }

  showProfiles() {}
}
