/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./assetgroup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./scope/scope.component.ngfactory";
import * as i3 from "./scope/scope.component";
import * as i4 from "./metagroup/metagroup.component.ngfactory";
import * as i5 from "./metagroup/metagroup.component";
import * as i6 from "../core/metagroup.service";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/common";
import * as i9 from "./assetgroup.component";
import * as i10 from "@angular/router";
var styles_AssetgroupComponent = [i0.styles];
var RenderType_AssetgroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssetgroupComponent, data: {} });
export { RenderType_AssetgroupComponent as RenderType_AssetgroupComponent };
function View_AssetgroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-scope", [], null, [[null, "onScopeSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onScopeSelected" === en)) {
        var pd_0 = (_co.onScopeSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ScopeComponent_0, i2.RenderType_ScopeComponent)), i1.ɵdid(1, 573440, null, 0, i3.ScopeComponent, [], { scopes: [0, "scopes"] }, { onScopeSelected: "onScopeSelected" })], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AssetgroupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-metagroup", [], null, [[null, "onMetagroupSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onMetagroupSelected" === en)) {
        var pd_0 = (_co.onMetagroupSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MetagroupComponent_0, i4.RenderType_MetagroupComponent)), i1.ɵdid(1, 704512, null, 0, i5.MetagroupComponent, [i6.MetagroupService], { scope: [0, "scope"] }, { onMetagroupSelected: "onMetagroupSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scope == null) ? null : _co.scope.label); _ck(_v, 1, 0, currVal_0); }, null); }
function View_AssetgroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "form", [["class", "col"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i7.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(2, 4210688, null, 0, i7.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i7.ControlContainer, null, [i7.NgForm]), i1.ɵdid(4, 16384, null, 0, i7.NgControlStatusGroup, [[4, i7.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetgroupComponent_2)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetgroupComponent_3)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = (_v.context.ngIf.length > 0); _ck(_v, 6, 0, currVal_7); var currVal_8 = _co.scope; _ck(_v, 8, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_AssetgroupComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetgroupComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.scopes$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AssetgroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-assetgroup", [], null, null, null, View_AssetgroupComponent_0, RenderType_AssetgroupComponent)), i1.ɵdid(1, 114688, null, 0, i9.AssetgroupComponent, [i10.Router, i10.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssetgroupComponentNgFactory = i1.ɵccf("xcm-assetgroup", i9.AssetgroupComponent, View_AssetgroupComponent_Host_0, {}, {}, []);
export { AssetgroupComponentNgFactory as AssetgroupComponentNgFactory };
