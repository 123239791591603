import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Metagroup} from 'app/assetgroup/metagroup/metagroup';

@Component({
  selector: 'xcm-metagroup-list',
  templateUrl: './metagroup-list.component.html',
  styleUrls: ['./metagroup-list.component.css']
})
export class MetagroupListComponent implements OnInit {
  @Input() metagroups: Metagroup[];
  @Output() onDetails: EventEmitter<Metagroup> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  details(metagroup: Metagroup) {
    this.onDetails.emit(metagroup);
  }
}
