/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./provider-profile.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../profile-list/profile-list.component.ngfactory";
import * as i3 from "../profile-list/profile-list.component";
import * as i4 from "@angular/common";
import * as i5 from "../profile-form/profile-form.component.ngfactory";
import * as i6 from "../profile-form/profile-form.component";
import * as i7 from "../../core/assetprofile.service";
import * as i8 from "../../core/validation.service";
import * as i9 from "../../assetgroup/scope/scope.component.ngfactory";
import * as i10 from "../../assetgroup/scope/scope.component";
import * as i11 from "./provider-profile.component";
import * as i12 from "@angular/router";
var styles_ProviderProfileComponent = [i0.styles];
var RenderType_ProviderProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProviderProfileComponent, data: {} });
export { RenderType_ProviderProfileComponent as RenderType_ProviderProfileComponent };
function View_ProviderProfileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary mb-2 mb-md-0 mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus fa-fw test-create-asset-profile"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create Asset Profile"]))], null, null); }
function View_ProviderProfileComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-outline-primary mb-2 mb-md-0 mr-2 test-assetprofile-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-close fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], null, null); }
function View_ProviderProfileComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row top-buffer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "xcm-profile-list", [], null, [[null, "onDelete"], [null, "onEdit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDelete" === en)) {
        var pd_0 = (_co.delete($event) !== false);
        ad = (pd_0 && ad);
    } if (("onEdit" === en)) {
        var pd_1 = (_co.edit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ProfileListComponent_0, i2.RenderType_ProfileListComponent)), i1.ɵdid(3, 573440, null, 0, i3.ProfileListComponent, [], { profiles: [0, "profiles"] }, { onDelete: "onDelete", onEdit: "onEdit" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.profiles$)); _ck(_v, 3, 0, currVal_0); }, null); }
function View_ProviderProfileComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row top-buffer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "xcm-profile-form", [], null, [[null, "onCancel"], [null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onCancel" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } if (("onSave" === en)) {
        var pd_1 = (_co.onSave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_ProfileFormComponent_0, i5.RenderType_ProfileFormComponent)), i1.ɵdid(5, 573440, null, 0, i6.ProfileFormComponent, [i7.AssetprofileService, i8.ValidationService], { assetprofile: [0, "assetprofile"], scopes: [1, "scopes"] }, { onSave: "onSave", onCancel: "onCancel" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.assetprofile; var currVal_1 = _v.parent.parent.context.ngIf; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
function View_ProviderProfileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "xcm-scope", [], null, [[null, "onScopeSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onScopeSelected" === en)) {
        var pd_0 = (_co.onScopeSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ScopeComponent_0, i9.RenderType_ScopeComponent)), i1.ɵdid(4, 573440, null, 0, i10.ScopeComponent, [], { scopes: [0, "scopes"] }, { onScopeSelected: "onScopeSelected" }), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProviderProfileComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProviderProfileComponent_4)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProviderProfileComponent_5)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProviderProfileComponent_6)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.parent.context.ngIf; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.showForm; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.showForm; _ck(_v, 12, 0, currVal_3); var currVal_4 = !_co.showForm; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.showForm; _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showForm; _ck(_v, 1, 0, currVal_0); }); }
function View_ProviderProfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProviderProfileComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.ngIf.length > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProviderProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProviderProfileComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.scopes$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProviderProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-provider-profile", [], null, null, null, View_ProviderProfileComponent_0, RenderType_ProviderProfileComponent)), i1.ɵdid(1, 114688, null, 0, i11.ProviderProfileComponent, [i7.AssetprofileService, i12.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProviderProfileComponentNgFactory = i1.ɵccf("xcm-provider-profile", i11.ProviderProfileComponent, View_ProviderProfileComponent_Host_0, {}, {}, []);
export { ProviderProfileComponentNgFactory as ProviderProfileComponentNgFactory };
