/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./assetgroup-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../assetgroup-form/assetgroup-form.component.ngfactory";
import * as i3 from "../assetgroup-form/assetgroup-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../core/validation.service";
import * as i6 from "@angular/common";
import * as i7 from "../../shared/filter-input/filter-input.component.ngfactory";
import * as i8 from "../../shared/filter-input/filter-input.component";
import * as i9 from "./assetgroup-list.component";
import * as i10 from "../../core/assetgroup.service";
import * as i11 from "@angular/router";
import * as i12 from "@ngrx/store";
var styles_AssetgroupListComponent = [i0.styles];
var RenderType_AssetgroupListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssetgroupListComponent, data: {} });
export { RenderType_AssetgroupListComponent as RenderType_AssetgroupListComponent };
function View_AssetgroupListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "btn btn-primary mb-2 mb-md-0 mr-2 pull-right test-add-assetgroup"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "hidden-xs-down"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create Assetgroup"]))], null, null); }
function View_AssetgroupListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "section", [["class", "jumbotron text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "jumbotron-heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "xcm-assetgroup-form", [], null, [[null, "onSave"], [null, "onCancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.addAssetgroup($event) !== false);
        ad = (pd_0 && ad);
    } if (("onCancel" === en)) {
        var pd_1 = (_co.onCancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AssetgroupFormComponent_0, i2.RenderType_AssetgroupFormComponent)), i1.ɵdid(5, 114688, null, 0, i3.AssetgroupFormComponent, [i4.FormBuilder, i5.ValidationService], { assetgroup: [0, "assetgroup"] }, { onSave: "onSave", onCancel: "onCancel" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.assetgroup; _ck(_v, 5, 0, currVal_0); }, null); }
function View_AssetgroupListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["No Assetgroups defined for ", ""])), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.scope$))) == null) ? null : tmp_0_0.label); _ck(_v, 1, 0, currVal_0); }); }
function View_AssetgroupListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-filter-input", [], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.setFilteredAssetgroups($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_FilterInputComponent_0, i7.RenderType_FilterInputComponent)), i1.ɵdid(1, 704512, null, 0, i8.FilterInputComponent, [], { collection: [0, "collection"], filtercallback: [1, "filtercallback"] }, { onChange: "onChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.ngIf; var currVal_1 = _co.filterCallback; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AssetgroupListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, null); }
function View_AssetgroupListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-outline-danger mb-2 mb-md-0 mr-2 test-delete-assetgroup"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-trash-o fa-lg fa-fw mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "hidden-xs-down"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], null, null); }
function View_AssetgroupListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-outline-primary mb-2 mb-md-0 mr-2 test-details-assetgroup"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDetails(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-eye fa-lg fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "hidden-xs-down"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Details"]))], null, null); }
function View_AssetgroupListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetgroupListComponent_8)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetgroupListComponent_9)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_1_0 = null; var currVal_1 = (((tmp_1_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.authorizations$))) == null) ? null : tmp_1_0.D_ASSETGROUP); _ck(_v, 7, 0, currVal_1); var tmp_2_0 = null; var currVal_2 = (((tmp_2_0 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.authorizations$))) == null) ? null : tmp_2_0.R_ASSETGROUP); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 3, 0, currVal_0); }); }
function View_AssetgroupListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["nothingtoshow", 2]], null, 0, null, View_AssetgroupListComponent_4)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetgroupListComponent_5)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetgroupListComponent_6)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetgroupListComponent_7)), i1.ɵdid(9, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.ngIf.length > 0); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_v.context.ngIf.length > 0); var currVal_2 = i1.ɵnov(_v, 2); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.filteredAssetgroups$)); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_AssetgroupListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["class", "btn btn-outline-primary mb-2 mb-md-0 mr-2 test-back-scope-selection"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reset() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["aria-hidden", ""], ["class", "fa fa-chevron-left fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "hidden-xs-down"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Scope Selection"])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "row top-buffer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Assetgroups "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetgroupListComponent_1)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetgroupListComponent_2)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetgroupListComponent_3)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.authorizations$))) == null) ? null : tmp_0_0.C_ASSETGROUP); _ck(_v, 11, 0, currVal_0); var currVal_1 = _co.displayform; _ck(_v, 14, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform(_co.assetgroups$)); _ck(_v, 16, 0, currVal_2); }, null); }
export function View_AssetgroupListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-assetgroup-list", [], null, null, null, View_AssetgroupListComponent_0, RenderType_AssetgroupListComponent)), i1.ɵdid(1, 114688, null, 0, i9.AssetgroupListComponent, [i10.AssetgroupService, i11.Router, i12.Store, i11.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssetgroupListComponentNgFactory = i1.ɵccf("xcm-assetgroup-list", i9.AssetgroupListComponent, View_AssetgroupListComponent_Host_0, {}, {}, []);
export { AssetgroupListComponentNgFactory as AssetgroupListComponentNgFactory };
