import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ValidationService} from 'app/core/validation.service';
import {QuestionBase} from 'app/shared/question-model/question-base';


@Component({
  selector: 'xcm-df-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFormQuestionComponent implements OnInit {
  @Input() question: QuestionBase<any>;
  @Input() form: FormGroup;
  constructor(private validationService: ValidationService) {}

  ngOnInit() {}

  showErrorFeedback(errorname: string) {
    return this.validationService.showErrorFeedback(this.form.get(this.question.id), errorname);
  }

  showSuccessFeedback() {
    return this.validationService.showSuccessFeedback(this.form.get(this.question.id));
  }

  showGlobalErrorFeedback() {
    return this.validationService.showGlobalErrorFeedback(this.form.get(this.question.id));
  }
}
