import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { ErrorHandlerService } from 'app/core/error-hanlder.service';
import { Checkbox, ProfileFormModel, Select, SelectOption, Textbox } from 'app/provider-profile/profile-form/profile-form-model';
import { AssetProfile, ExternalSystem, ProfileElementFormat } from 'app/shared/models/asset';
import { Scope } from 'app/shared/models/scope';
import * as ControlType from 'app/shared/question-model/control-type';
import { Option } from 'app/shared/question-model/option';
import { CheckboxQuestion } from 'app/shared/question-model/question-checkbox';
import { DropdownQuestion } from 'app/shared/question-model/question-dropdown';
import { MultiSelectQuestion } from 'app/shared/question-model/question-multi-select';
import { RadiosQuestion } from 'app/shared/question-model/question-radios';
import { TextboxQuestion } from 'app/shared/question-model/question-textbox';
import { catchError, map, tap } from 'rxjs/operators';
var AssetprofileService = /** @class */ (function () {
    function AssetprofileService(http, formBuilder, errorHandlerService) {
        this.http = http;
        this.formBuilder = formBuilder;
        this.errorHandlerService = errorHandlerService;
        this.apiUrl = '/v1/xcm/assetprofiles';
        this.identifierRegex = /^[a-zA-Z][a-zA-Z0-9]*$/;
    }
    AssetprofileService.prototype.getAssetProfiles = function (assetgroupId) {
        return this.http.get(this.apiUrl + "?assetgroupId=" + assetgroupId)
            .pipe(map(function (res) { return res.data || res; }), catchError(this.errorHandlerService.handleError));
    };
    AssetprofileService.prototype.getAssetProfilesByScope = function (scope) {
        return this.http.get(this.apiUrl + "?scope=" + scope)
            .pipe(map(function (res) { return res.data || res; }), catchError(this.errorHandlerService.handleError));
    };
    AssetprofileService.prototype.getAssetProfilesByScopeAndMetagroup = function (scope, metagroupId) {
        return this.http
            .get(this.apiUrl + "?scope=" + scope + "&metagroupid=" + metagroupId)
            .pipe(map(function (res) { return res.data || res; }), catchError(this.errorHandlerService.handleError));
    };
    AssetprofileService.prototype.addAssetProfile = function (assetprofile) {
        return this.http.post("" + this.apiUrl, assetprofile)
            .pipe(tap(this.errorHandlerService.handleWarning), catchError(this.errorHandlerService.handleError));
    };
    AssetprofileService.prototype.updateAssetProfile = function (assetprofile) {
        return this.http.put(this.apiUrl + "/" + assetprofile.id, assetprofile)
            .pipe(tap(this.errorHandlerService.handleWarning), catchError(this.errorHandlerService.handleError));
    };
    AssetprofileService.prototype.deleteAssetProfile = function (assetprofileId) {
        return this.http.delete(this.apiUrl + "/" + assetprofileId)
            .pipe(tap(this.errorHandlerService.handleWarning), catchError(this.errorHandlerService.handleError));
    };
    AssetprofileService.prototype.getAssetQuestions = function (asset) {
        var questions = this.getQuestions(asset.profile);
        var _loop_1 = function (question) {
            if (asset.payload) {
                var assetpayload = asset.payload.find(function (payload) { return payload.profileElementFormatId === question.id; });
                if (assetpayload) {
                    switch (question.controlType) {
                        case ControlType.CHECKBOX:
                            question.value = assetpayload.value === 'true';
                            break;
                        case ControlType.MULTISELECT:
                            question.value = assetpayload.value.split(/\s*,\s*/);
                            break;
                        default:
                            question.value = assetpayload.value;
                            break;
                    }
                }
            }
        };
        for (var _i = 0, questions_1 = questions; _i < questions_1.length; _i++) {
            var question = questions_1[_i];
            _loop_1(question);
        }
        return questions;
    };
    AssetprofileService.prototype.getQuestions = function (assetProfile) {
        var questions = [];
        if (assetProfile.profileElement) {
            var _loop_2 = function (profileformat) {
                switch (profileformat.controlType) {
                    case ControlType.TEXTBOX:
                        var textbox = new TextboxQuestion(profileformat);
                        if (profileformat.options && profileformat.options.length > 0 &&
                            profileformat.options[0].id === profileformat.id &&
                            profileformat.options[0].value) {
                            textbox.value = profileformat.options[0].value;
                        }
                        if (profileformat.concealed) {
                            textbox.type = 'password';
                        }
                        questions.push(textbox);
                        break;
                    case ControlType.CHECKBOX:
                        var checkbox = new CheckboxQuestion(profileformat);
                        if (profileformat.options && profileformat.options.length > 0 &&
                            profileformat.options[0].id === profileformat.id &&
                            profileformat.options[0].value) {
                            checkbox.value = (profileformat.options[0].value === 'true');
                        }
                        questions.push(checkbox);
                        break;
                    case ControlType.DROPDOWN:
                        var dropdown = new DropdownQuestion(profileformat);
                        var defaultval_1 = null;
                        if (profileformat.options && profileformat.options.length > 0 &&
                            profileformat.options.some(function (value) {
                                return (value.default === true) ? ((defaultval_1 = value.id), true) : false;
                            }) &&
                            defaultval_1) {
                            dropdown.value = defaultval_1;
                        }
                        questions.push(dropdown);
                        break;
                    case ControlType.RADIOS:
                        var radios = new RadiosQuestion(profileformat);
                        var defaultvalue_1 = null;
                        if (profileformat.options && profileformat.options.length > 0 &&
                            profileformat.options.some(function (value) {
                                return (value.default === true) ? ((defaultvalue_1 = value.id), true) : false;
                            }) &&
                            defaultvalue_1) {
                            radios.value = defaultvalue_1;
                        }
                        questions.push(radios);
                        break;
                    case ControlType.MULTISELECT:
                        var multiselect = new MultiSelectQuestion(profileformat);
                        var defaultoptions = [];
                        if (profileformat.options && profileformat.options.length > 0) {
                            defaultoptions = profileformat.options.filter(function (value) { return value.default === true; });
                            for (var _i = 0, defaultoptions_1 = defaultoptions; _i < defaultoptions_1.length; _i++) {
                                var option = defaultoptions_1[_i];
                                multiselect.value.push(option.id);
                            }
                        }
                        questions.push(multiselect);
                        break;
                    default:
                        questions.push(new TextboxQuestion(profileformat));
                        break;
                }
            };
            for (var _i = 0, _a = assetProfile.profileElement; _i < _a.length; _i++) {
                var profileformat = _a[_i];
                _loop_2(profileformat);
            }
        }
        return questions;
    };
    AssetprofileService.prototype.singleDefaultOptionValidator = function (array) {
        var countchecked = array.controls.filter(function (item) { return item.value.default; }).length;
        if (countchecked > 1) {
            return {
                singledefault: true
            };
        }
        return null;
    };
    AssetprofileService.prototype.uniqueIdOptionValidator = function (array) {
        var testObject = {};
        var seenDuplicate = false;
        array.controls.some(function (item) {
            var trimmedid = item.value.id.trim();
            if (item.value.id) {
                if (trimmedid in testObject) {
                    seenDuplicate = true;
                }
                else {
                    testObject[trimmedid] = item;
                }
            }
            return seenDuplicate;
        });
        if (seenDuplicate) {
            return {
                uniqueid: true
            };
        }
        return null;
    };
    AssetprofileService.prototype.uniqueLabelOptionValidator = function (array) {
        var testObject = {};
        var seenDuplicate = false;
        array.controls.some(function (item) {
            var trimmedlabel = item.value.label.trim();
            if (item.value.label) {
                if (trimmedlabel in testObject) {
                    seenDuplicate = true;
                }
                else {
                    testObject[trimmedlabel] = item;
                }
            }
            return seenDuplicate;
        });
        if (seenDuplicate) {
            return {
                uniquelabel: true
            };
        }
        return null;
    };
    AssetprofileService.prototype.regexFormatValidator = function (control) {
        try {
            var a = new RegExp(control.value);
            if (a) {
                return null;
            }
        }
        catch (e) {
            return { regexformat: true };
        }
        return null;
    };
    AssetprofileService.prototype.getProfileForm = function (profileFormModel) {
        var _this = this;
        if (profileFormModel === void 0) { profileFormModel = new ProfileFormModel(); }
        return this.formBuilder.group({
            scope: [profileFormModel.scope || '', [Validators.required]],
            label: [profileFormModel.label || '', [Validators.required]],
            system: [profileFormModel.system || '', [Validators.required]],
            subsystem: [profileFormModel.subsystem || ''],
            textboxes: this.formBuilder.array(profileFormModel.textboxes.map(function (item) { return _this.getTextboxForm(item); })),
            dropdowns: this.formBuilder.array(profileFormModel.dropdowns.map(function (item) { return _this.getSelectForm(item); })),
            checkboxes: this.formBuilder.array(profileFormModel.checkboxes.map(function (item) { return _this.getCheckboxForm(item); })),
            multiselects: this.formBuilder.array(profileFormModel.multiselects.map(function (item) { return _this.getMultiSelectForm(item); })),
            radios: this.formBuilder.array(profileFormModel.radios.map(function (item) { return _this.getSelectForm(item); }))
        });
    };
    AssetprofileService.prototype.getOptionForm = function (selectOption) {
        if (selectOption === void 0) { selectOption = new SelectOption(); }
        return this.formBuilder.group({
            id: [selectOption.id || '', [Validators.required, Validators.pattern(this.identifierRegex)]],
            label: [selectOption.label || '', [Validators.required]],
            default: [selectOption.default || false]
        });
    };
    AssetprofileService.prototype.getSelectForm = function (select) {
        if (select === void 0) { select = new Select(); }
        var selectform = this.getMultiSelectFormNoValidators(select);
        var options = selectform.get('options');
        if (options) {
            options.setValidators([
                this.singleDefaultOptionValidator, Validators.required, Validators.minLength(2),
                this.uniqueIdOptionValidator, this.uniqueLabelOptionValidator
            ]);
        }
        return selectform;
    };
    AssetprofileService.prototype.getMultiSelectForm = function (select) {
        if (select === void 0) { select = new Select(); }
        var selectform = this.getMultiSelectFormNoValidators(select);
        var options = selectform.get('options');
        if (options) {
            options.setValidators([
                Validators.required, Validators.minLength(2), this.uniqueIdOptionValidator,
                this.uniqueLabelOptionValidator
            ]);
        }
        return selectform;
    };
    AssetprofileService.prototype.getMultiSelectFormNoValidators = function (select) {
        if (select === void 0) { select = new Select(); }
        return this.formBuilder.group({
            id: [select.id || '', [Validators.required, Validators.pattern(this.identifierRegex)]],
            label: [select.label || '', [Validators.required]],
            description: [select.description || ''],
            order: [select.order || 1],
            required: [select.required || false],
            options: this.formBuilder.array(this.getOptions(select.options))
        });
    };
    AssetprofileService.prototype.getOptions = function (options) {
        var _this = this;
        var controlConfigs = [];
        if (options) {
            controlConfigs = options.map(function (item) { return _this.getOptionForm(item); });
        }
        else {
            controlConfigs = [this.getOptionForm(), this.getOptionForm()];
        }
        return controlConfigs;
    };
    AssetprofileService.prototype.getTextboxForm = function (textbox) {
        if (textbox === void 0) { textbox = new Textbox(); }
        return this.formBuilder.group({
            id: [textbox.id || '', [Validators.required, Validators.pattern(this.identifierRegex)]],
            label: [textbox.label || '', [Validators.required]],
            regex: [textbox.regex || '', [this.regexFormatValidator]],
            description: [textbox.description || ''],
            order: [textbox.order || 1],
            default: [textbox.default || ''],
            concealed: [textbox.concealed || false],
            required: [textbox.required || false]
        });
    };
    AssetprofileService.prototype.getCheckboxForm = function (checkbox) {
        if (checkbox === void 0) { checkbox = new Checkbox(); }
        return this.formBuilder.group({
            id: [checkbox.id || '', [Validators.required, Validators.pattern(this.identifierRegex)]],
            label: [checkbox.label || '', [Validators.required]],
            description: [checkbox.description || ''],
            order: [checkbox.order || 1],
            default: [checkbox.default || false]
        });
    };
    AssetprofileService.prototype.getControlType = function (name) {
        var controlType = '';
        switch (name) {
            case 'radios':
                controlType = ControlType.RADIOS;
                break;
            case 'multiselects':
                controlType = ControlType.MULTISELECT;
                break;
            case 'dropdowns':
                controlType = ControlType.DROPDOWN;
                break;
            default:
                break;
        }
        return controlType;
    };
    AssetprofileService.prototype.assetProfileToProfileForm = function (assetProfile) {
        var _this = this;
        var profileForm = new ProfileFormModel();
        profileForm.label = assetProfile.label;
        profileForm.system = assetProfile.externalSystem.system;
        profileForm.subsystem = assetProfile.externalSystem.subsystem;
        profileForm.scope = assetProfile.scope.label;
        profileForm.textboxes = assetProfile.profileElement ?
            assetProfile.profileElement
                .filter(function (elementFormat) { return elementFormat.controlType === ControlType.TEXTBOX; })
                .map(function (textboxprofile) {
                var textbox = new Textbox();
                for (var key in textboxprofile) {
                    if (textboxprofile.hasOwnProperty(key) && key !== 'controlType') {
                        if (key === 'options') {
                            if (textboxprofile.options) {
                                textboxprofile.options.forEach(function (option) { return textbox.default = option.value; });
                            }
                        }
                        else {
                            textbox[key] = textboxprofile[key];
                        }
                    }
                }
                return textbox;
            }) :
            [];
        profileForm.checkboxes = assetProfile.profileElement ?
            assetProfile.profileElement
                .filter(function (elementFormat) { return elementFormat.controlType === ControlType.CHECKBOX; })
                .map(function (checkboxprofile) {
                var checkbox = new Checkbox();
                for (var key in checkboxprofile) {
                    if (checkboxprofile.hasOwnProperty(key) && key !== 'controlType') {
                        if (key === 'options') {
                            if (checkboxprofile.options) {
                                checkboxprofile.options.forEach(function (option) { return checkbox.default = option.default || false; });
                            }
                        }
                        else {
                            checkbox[key] = checkboxprofile[key];
                        }
                    }
                }
                return checkbox;
            }) :
            [];
        ['radios', 'dropdowns', 'multiselects'].forEach(function (name) {
            profileForm[name] = assetProfile.profileElement ?
                assetProfile.profileElement
                    .filter(function (elementFormat) { return elementFormat.controlType === _this.getControlType(name); })
                    .map(function (selectprofile) {
                    var select = new Select();
                    for (var key in selectprofile) {
                        if (selectprofile.hasOwnProperty(key) && key !== 'controlType') {
                            if (key === 'options') {
                                if (selectprofile.options) {
                                    select.options = selectprofile.options.map(function (optionprofile) {
                                        var selectOption = new SelectOption();
                                        selectOption.id = optionprofile.id;
                                        selectOption.label = optionprofile.value;
                                        selectOption.default = optionprofile.default || false;
                                        select.options.push(selectOption);
                                        return selectOption;
                                    });
                                }
                            }
                            else {
                                select[key] = selectprofile[key];
                            }
                        }
                    }
                    return select;
                }) :
                [];
        });
        return profileForm;
    };
    AssetprofileService.prototype.profileFormToAssetProfile = function (profileForm) {
        var _this = this;
        var assetProfile = new AssetProfile();
        assetProfile.label = profileForm.label;
        var externalSystem = new ExternalSystem();
        externalSystem.system = profileForm.system;
        externalSystem.subsystem = profileForm.subsystem;
        assetProfile.externalSystem = externalSystem;
        var scope = new Scope();
        scope.label = profileForm.scope;
        assetProfile.scope = scope;
        profileForm.textboxes.forEach(function (item) {
            var profileElementFormat = new ProfileElementFormat();
            profileElementFormat.controlType = ControlType.TEXTBOX;
            for (var key in item) {
                if (item.hasOwnProperty(key)) {
                    if (key === 'default') {
                        var option = new Option();
                        option.id = item.id;
                        option.value = item.default;
                        profileElementFormat.options.push(option);
                    }
                    else {
                        profileElementFormat[key] = item[key];
                    }
                }
            }
            assetProfile.profileElement.push(profileElementFormat);
        });
        profileForm.checkboxes.forEach(function (item) {
            var profileElementFormat = new ProfileElementFormat();
            profileElementFormat.controlType = ControlType.CHECKBOX;
            for (var key in item) {
                if (item.hasOwnProperty(key)) {
                    if (key === 'default') {
                        var option = new Option();
                        option.id = item.id;
                        option.default = item.default;
                        profileElementFormat.options.push(option);
                    }
                    else {
                        profileElementFormat[key] = item[key];
                    }
                }
            }
            assetProfile.profileElement.push(profileElementFormat);
        });
        ['radios', 'dropdowns', 'multiselects'].forEach(function (name) {
            profileForm[name].forEach(function (item) {
                var profileElementFormat = new ProfileElementFormat();
                profileElementFormat.controlType = _this.getControlType(name);
                for (var key in item) {
                    if (item.hasOwnProperty(key)) {
                        if (key === 'options') {
                            item.options.forEach(function (opt) {
                                var option = new Option();
                                option.id = opt.id;
                                option.default = opt.default;
                                option.value = opt.label;
                                profileElementFormat.options.push(option);
                            });
                        }
                        else {
                            profileElementFormat[key] = item[key];
                        }
                    }
                }
                assetProfile.profileElement.push(profileElementFormat);
            });
        });
        return assetProfile;
    };
    return AssetprofileService;
}());
export { AssetprofileService };
