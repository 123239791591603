import { EventEmitter, OnChanges } from '@angular/core';
import { AssetgroupService } from 'app/core/assetgroup.service';
import { MetagroupService } from 'app/core/metagroup.service';
import { forkJoin as observableForkJoin } from 'rxjs';
import { concat, map, publishReplay, refCount } from 'rxjs/operators';
var UnlinkedMembersComponent = /** @class */ (function () {
    function UnlinkedMembersComponent(metagroupService, assetgroupService) {
        this.metagroupService = metagroupService;
        this.assetgroupService = assetgroupService;
        this.sortAscending = true;
        this.onCancel = new EventEmitter();
        this.onSave = new EventEmitter();
        this.entities = new Array();
        this.processing = false;
        this.selectAll = false;
        this.filterCallback = this.assetgroupService.filterEntities;
        this.totalEntities = 0;
        this.selectedEntitiesCount = 0;
    }
    UnlinkedMembersComponent.prototype.ngOnChanges = function () {
        var _this = this;
        if (this.metagroup && this.assetgroup) {
            this.reloadentities$ = this.metagroupService.getMembers(this.metagroup, this.assetgroup);
            this.entities$ = this.reloadentities$.pipe(publishReplay(), refCount(), map(function (entity) { return entity.sort(function (a, b) { return a.label.localeCompare(b.label); }); }));
            this.filteredEntities$ = this.entities$;
            this.entities$.subscribe(function (entities) {
                _this.entities = entities;
                _this.totalEntities = entities.length;
            });
        }
    };
    UnlinkedMembersComponent.prototype.onSubmit = function () {
        var _this = this;
        var batchSize = 100; // Set the desired batch size
        var selectedEntities = this.entities.filter(function (entity) { return entity.checked; });
        this.selectedEntitiesCount = selectedEntities.length;
        var numBatches = Math.ceil(selectedEntities.length / batchSize);
        var _loop_1 = function (i) {
            var start = i * batchSize;
            var end = start + batchSize;
            var batchEntities = selectedEntities.slice(start, end);
            var arrayObservables = batchEntities.map(function (member) {
                return _this.assetgroupService.linkEntity(_this.assetgroup, member);
            });
            if (arrayObservables.length > 0) {
                console.log('Number of items to process in batch', arrayObservables.length);
                this_1.processing = true;
                var temporary$_1 = this_1.reloadentities$.pipe(publishReplay(), refCount());
                this_1.linkedEntitiesSub = observableForkJoin(arrayObservables)
                    .pipe(concat(temporary$_1))
                    .subscribe(function () {
                    _this.filteredEntities$ = temporary$_1;
                    _this.processing = false;
                    _this.onSave.emit(null);
                }, function () {
                    _this.processing = false;
                });
            }
        };
        var this_1 = this;
        for (var i = 0; i < numBatches; i++) {
            _loop_1(i);
        }
    };
    UnlinkedMembersComponent.prototype.abort = function () {
        if (this.linkedEntitiesSub) {
            this.linkedEntitiesSub.unsubscribe();
        }
        this.processing = false;
    };
    UnlinkedMembersComponent.prototype.onSort = function () {
        var _this = this;
        this.entities = this.entities.sort(function (a, b) {
            return _this.sortAscending ? a.label.localeCompare(b.label) : b.label.localeCompare(a.label);
        });
    };
    UnlinkedMembersComponent.prototype.onCancelled = function () {
        this.abort();
        this.onCancel.emit(null);
    };
    UnlinkedMembersComponent.prototype.noSelection = function () {
        return !this.entities.some(function (entity) { return entity.checked ? entity.checked : false; });
    };
    UnlinkedMembersComponent.prototype.setFilteredEntities = function (filteredItems) {
        this.filteredEntities$ = filteredItems;
    };
    UnlinkedMembersComponent.prototype.selectAllUnselectAll = function () {
        var _this = this;
        this.selectAll = !this.selectAll;
        this.entities.forEach(function (entity) { return entity.checked = _this.selectAll; });
        this.updateSelectedCount();
    };
    UnlinkedMembersComponent.prototype.updateSelectedCount = function () {
        this.selectedEntitiesCount = this.entities.filter(function (entity) { return entity.checked; }).length;
    };
    return UnlinkedMembersComponent;
}());
export { UnlinkedMembersComponent };
