import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {DeleteUsecase} from './delete-usecase';

@Component({
  selector: 'xcm-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {
  deleteAsset = DeleteUsecase.ASSET;
  deleteAssetgroup = DeleteUsecase.ASSETGROUP;
  unlinkAssetgroup = DeleteUsecase.ASSETGROUP_UNLINK;
  @Input() usecase: DeleteUsecase;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
