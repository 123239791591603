<p *ngFor="let profile of profilesToLink$ | async">
{{ profile.id }} {{profile.label}}
</p>
<button type="button" class="btn btn-primary mb-2 mb-md-0 mr-2" (click)="showProfiles()"> <i class="fa fa-plus fa-fw"></i>Add a Profile to metagroup</button>

<div class="row top-buffer">
  <div class="col">
      <xcm-profile-list [profiles]="profiles$ | async" [showButton]="false"></xcm-profile-list>      
  </div>
</div>
