import {Component, Input, OnInit} from '@angular/core';
import {QuestionBase} from 'app/shared/question-model/question-base';

@Component({
  selector: 'xcm-dynamic-view',
  templateUrl: './dynamic-view.component.html',
  styleUrls: ['./dynamic-view.component.css']
})
export class DynamicViewComponent implements OnInit {
  private sortedquestions: QuestionBase<any>[];
  constructor() {}

  ngOnInit() {}

  @Input()
  set questions(questions: QuestionBase<any>[]) {
    this.sortedquestions = questions.sort((q1, q2) => +q1.order - +q2.order);
  }

  get questions() {
    return this.sortedquestions;
  }
}
