import { OnInit } from '@angular/core';
var DynamicViewComponent = /** @class */ (function () {
    function DynamicViewComponent() {
    }
    DynamicViewComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(DynamicViewComponent.prototype, "questions", {
        get: function () {
            return this.sortedquestions;
        },
        set: function (questions) {
            this.sortedquestions = questions.sort(function (q1, q2) { return +q1.order - +q2.order; });
        },
        enumerable: true,
        configurable: true
    });
    return DynamicViewComponent;
}());
export { DynamicViewComponent };
