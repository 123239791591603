<section class="jumbotron text-center">
  <div class="jumbotron-heading">
    <ng-container *ngIf="!editMode">
      <h1 class="display-4">{{ assetgroup.label }}</h1>
      <p class="lead">
        <span *ngIf="assetgroup.cascade; else deactivated" class="badge badge-success" i18n="@@assetgroup.cascade">CASCADE</span>
        <ng-template #deactivated ><span class="badge badge-dark" i18n="@@assetgroup.notcascade">NOT CASCADED</span></ng-template>
      </p>
      <p class="lead">
        <a *ngIf="(authorizations$ | async)?.U_ASSETGROUP" class="btn btn-lg btn-outline-primary mb-2 mb-md-0 mr-2 test-edit-assetgroup" href="" (click)="$event.preventDefault();onEdit();" aria-label="Edit">
          <i class="fa fa-pencil fa-lg" aria-hidden="true"> Edit</i>
        </a>
        <a *ngIf="(authorizations$ | async)?.D_ASSETGROUP" class="btn btn-lg btn-outline-danger mb-2 mb-md-0 mr-2 test-delete-assetgroup" href="" (click)="$event.preventDefault();onDelete();" aria-label="Delete">
          <i class="fa fa-trash fa-lg" aria-hidden="true"> Delete</i>
        </a>
      </p>
    </ng-container>
    <ng-container *ngIf="editMode">
      <xcm-assetgroup-form [assetgroup]="assetgroup" (onSave)="onSubmit($event)" (onCancel)="onCancel()"></xcm-assetgroup-form>
    </ng-container>
  </div>
  <a class="carousel-control-prev test-back" routerLink=".." role="button" data-slide="prev">
    <i class="fa fa-chevron-left fa-4x" aria-hidden="true"></i>
    <span class="sr-only">Previous</span>
  </a>
</section>