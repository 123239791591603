
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers/reducers';
import {ChangeScope} from 'app/reducers/scope.actions';
import {Scope} from 'app/shared/models/scope';
import {EMPTY, Observable, of} from 'rxjs';

@Injectable()
export class ScopeResolver implements Resolve<Scope> {
  constructor(private store: Store<AppState>) {}
  resolve(route: ActivatedRouteSnapshot): Observable<Scope> {
    let scope: Observable<Scope> = EMPTY;
    const scopeLabel = route.paramMap.get('scope') || '';

    if (route.parent && route.parent.data.grantedScopes) {
      const selectedScope =
          (<Array<Scope>>route.parent.data.grantedScopes).find(data => data.label === scopeLabel);
      if (selectedScope) {
        scope = of(selectedScope);
        // update the store
        this.store.dispatch(new ChangeScope(selectedScope));
      }
    }
    return scope;
  }
}
