
import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {ScopeService} from 'app/core/scope.service';
import {Scope} from 'app/shared/models/scope';
import {Observable} from 'rxjs';
import {publishReplay, refCount} from 'rxjs/operators';


@Injectable()
export class ScopesResolver implements Resolve<Scope[]> {
  constructor(private scopeService: ScopeService) {}

  resolve(): Observable<Scope[]> {
    return this.scopeService.getScopes().pipe(
        publishReplay(1),
        refCount(),
    );
  }
}
