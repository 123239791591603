<div class="row">
  <div class="col">
      <button type="button" class="btn btn-outline-primary mb-2 mb-md-0 mr-2 test-back-scope-selection" (click)="reset()"><span class="fa fa-chevron-left fa-fw" aria-hidden></span> <span i18n="@@general.backtoscopeselection" class="hidden-xs-down">Scope Selection</span></button>
  </div>
</div>

<div class="row top-buffer">
  <div class="col">
    <h1>Assetgroups
      <span *ngIf="(authorizations$ | async)?.C_ASSETGROUP">
        <button type="button" class="btn btn-primary mb-2 mb-md-0 mr-2 pull-right test-add-assetgroup" (click)="showForm()"><i class="fa fa-plus fa-fw" aria-hidden="true"></i><span i18n="@@assetgroup.add" class="hidden-xs-down">Create Assetgroup</span></button>      
      </span>
    </h1>
  </div>
</div>
<div *ngIf="displayform" class="row">
  <div class="col">
        <section class="jumbotron text-center">
                <div class="jumbotron-heading">
      <xcm-assetgroup-form [assetgroup]="assetgroup" (onSave)="addAssetgroup($event)" (onCancel)="onCancel()"></xcm-assetgroup-form>  
      </div>
      </section>        
  </div>
</div>
<ng-container *ngIf="assetgroups$| async as assetgroups">
<hr/>
<ng-template #nothingtoshow><p>No Assetgroups defined for {{ (scope$ | async)?.label }}</p></ng-template>

<xcm-filter-input *ngIf="assetgroups.length>0" [collection]="assetgroups" [filtercallback]="filterCallback" (onChange)="setFilteredAssetgroups($event)"></xcm-filter-input>
<hr *ngIf="assetgroups.length>0; else nothingtoshow">
<ul class="list-group">
  <li class="list-group-item" *ngFor="let agr of (filteredAssetgroups$ | async)">
    <div class="row align-items-center">
        <span class="col-md-6">{{ agr.label }}</span>
        <div class="col-md-6">
          <div class="pull-right">
              <button *ngIf="(authorizations$ | async)?.D_ASSETGROUP"  type="button" (click)="onDelete(agr)" class="btn btn-outline-danger mb-2 mb-md-0 mr-2 test-delete-assetgroup"><i class="fa fa-trash-o fa-lg fa-fw mr-2" aria-hidden="true" i18n></i><span class="hidden-xs-down">Delete</span></button>
              <button *ngIf="(authorizations$ | async)?.R_ASSETGROUP" type="button" (click)="onDetails(agr)" class="btn btn-outline-primary mb-2 mb-md-0 mr-2 test-details-assetgroup"><i class="fa fa-eye fa-lg fa-fw" aria-hidden="true" i18n></i><span class="hidden-xs-down">Details</span></button>              
          </div>
        </div>
    </div>
  </li>
</ul>
</ng-container>
