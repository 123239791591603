import { SapConfig } from 'app/login/sap.config';
import { Observable } from 'rxjs';
import { Auth } from './auth';
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService() {
        // - (dash) and _ (underscore) are here to replace + and / for URLs
        // This does not work depending on the string size since there is no complement ==
        // readonly base64Regex: RegExp =
        //    /^(?:[-_A-Za-z0-9+/]{4})*(?:[-_A-Za-z0-9+/]{2}==|[-_A-Za-z0-9+/]{3}=)?$/;
        this.base64UrlEncodedRegex = /^(?:[-_A-Za-z0-9+/=])+$/;
    }
    AuthenticationService.prototype.getAuthorizationHeader = function () {
        var authHeader = '';
        if (this.isIdTokenValid()) {
            var bearerToken = localStorage.getItem('xcm_access_token');
            if (bearerToken) {
                authHeader = "Bearer " + bearerToken;
            }
        }
        return authHeader;
    };
    Object.defineProperty(AuthenticationService.prototype, "isLoggedIn", {
        get: function () {
            return localStorage.getItem('xcm_access_token') !== null && this.isIdTokenValid();
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.isIdTokenValid = function () {
        var idtoken = this.idTokenPayload;
        return (idtoken.iss === 'accounts.amadeus.com') && (idtoken.exp * 1000 > Date.now());
    };
    Object.defineProperty(AuthenticationService.prototype, "idTokenPayload", {
        get: function () {
            if (!this.idTokenCache || Object.keys(this.idTokenCache).length === 0) {
                var idTokenPayload = {};
                var xcmIdToken = localStorage.getItem('xcm_id_token');
                if (xcmIdToken && xcmIdToken.match(this.base64UrlEncodedRegex)) {
                    idTokenPayload = JSON.parse(atob(xcmIdToken));
                }
                this.idTokenCache = idTokenPayload;
            }
            return this.idTokenCache;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "usap", {
        get: function () {
            var usap = '';
            var idtoken = this.idTokenPayload;
            if (idtoken) {
                var auth = new Auth();
                if (auth.guessPhaseFromLocation() === 'usr') {
                    usap = SapConfig.localSap;
                }
                else {
                    var SapConfigUsap = SapConfig.usap[idtoken.env] || SapConfig.usap['DEFAULT'];
                    var usaproot = (SapConfigUsap[idtoken.organization] || SapConfigUsap['all_star'] ||
                        this.computeUsap(idtoken));
                    usap = "" + usaproot + SapConfig.getPhaseLetter(idtoken.env);
                }
            }
            return usap;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.computeUsap = function (idtoken) {
        var sap = '';
        if (idtoken) {
            var orgaMaxSize = 4;
            var orgaSAP = idtoken.organization.replace('-', '').substring(0, orgaMaxSize);
            sap = "1ASIUSASXCM" + orgaSAP;
        }
        return sap;
    };
    AuthenticationService.prototype.login = function (fragment) {
        var _this = this;
        if (fragment === void 0) { fragment = ''; }
        return new Observable(function (obs) {
            var auth = new Auth();
            if (!fragment && !_this.isLoggedIn) {
                // console.log('fragment is ', fragment);
                location.assign(auth.url(auth.guessPhaseFromLocation()));
            }
            var re = /access_token=([^&]+)&id_token=([^&]+)&token_type=(\w+)&expires_in=(\d+)/;
            try {
                if (fragment) {
                    var match = re.exec(fragment);
                    if (match) {
                        // console.log((new Date()).getSeconds(), match[1], match[2], match[3], match[4]);
                        _this.accessToken = match[1];
                        _this.idToken = match[2];
                        _this.saveIdToken();
                        // expire the token
                        _this.startExpiresTimer(+match[4]);
                        localStorage.setItem('xcm_access_token', _this.accessToken);
                        // emit true
                        obs.next(true);
                        obs.complete();
                    }
                    else {
                        console.log(fragment + " does not match");
                    }
                }
            }
            catch (err) {
                console.log(err);
                obs.throw(err);
            }
        });
    };
    AuthenticationService.prototype.saveIdToken = function () {
        var tokens = this.idToken.split('.');
        if (tokens.length === 3) {
            // url decode instead => modify _ by / and - by +
            var idTokenb64 = tokens[1].replace('_', '/').replace('-', '+');
            localStorage.setItem('xcm_id_token', idTokenb64);
        }
    };
    AuthenticationService.prototype.logout = function () {
        this.expiresTimerId = null;
        this.idTokenCache = undefined;
        localStorage.removeItem('xcm_id_token');
        localStorage.removeItem('xcm_access_token');
    };
    AuthenticationService.prototype.startExpiresTimer = function (seconds) {
        var _this = this;
        if (this.expiresTimerId) {
            clearTimeout(this.expiresTimerId);
        }
        this.expiresTimerId = setTimeout(function () {
            console.log('Session has expired');
            _this.logout();
        }, seconds * 1000);
    };
    return AuthenticationService;
}());
export { AuthenticationService };
