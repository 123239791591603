/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dynamic-form.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dynamic-form-question.component.ngfactory";
import * as i3 from "./dynamic-form-question.component";
import * as i4 from "../../core/validation.service";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./dynamic-form.component";
import * as i8 from "./question-control.service";
var styles_DynamicFormComponent = [i0.styles];
var RenderType_DynamicFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DynamicFormComponent, data: {} });
export { RenderType_DynamicFormComponent as RenderType_DynamicFormComponent };
function View_DynamicFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "xcm-df-question", [], null, null, null, i2.View_DynamicFormQuestionComponent_0, i2.RenderType_DynamicFormQuestionComponent)), i1.ɵdid(2, 114688, null, 0, i3.DynamicFormQuestionComponent, [i4.ValidationService], { question: [0, "question"], form: [1, "form"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.form; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_DynamicFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-outline-primary mb-2 mb-md-0 mr-2 test-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancelled() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-primary mb-2 mb-md-0 mr-2 test-save"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.form.valid; _ck(_v, 3, 0, currVal_0); }); }
function View_DynamicFormComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Empty profile"]))], null, null); }
export function View_DynamicFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.onSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i5.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(2, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_1)), i1.ɵdid(6, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormComponent_2)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noquestions", 2]], null, 0, null, View_DynamicFormComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.questions; _ck(_v, 6, 0, currVal_8); var currVal_9 = (_co.questions.length > 0); var currVal_10 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_DynamicFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-dynamic-form", [], null, null, null, View_DynamicFormComponent_0, RenderType_DynamicFormComponent)), i1.ɵdid(1, 573440, null, 0, i7.DynamicFormComponent, [i8.QuestionControlService], null, null)], null, null); }
var DynamicFormComponentNgFactory = i1.ɵccf("xcm-dynamic-form", i7.DynamicFormComponent, View_DynamicFormComponent_Host_0, { questions: "questions" }, { onSave: "onSave", onCancel: "onCancel" }, []);
export { DynamicFormComponentNgFactory as DynamicFormComponentNgFactory };
