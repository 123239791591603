
import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Member} from 'app/assetgroup/members/member';
import {AssetgroupService} from 'app/core/assetgroup.service';
import {MetagroupService} from 'app/core/metagroup.service';
import {forkJoin as observableForkJoin, Observable, Subscription} from 'rxjs';
import {concat, map, publishReplay, refCount} from 'rxjs/operators';

@Component({
  selector: 'xcm-unlinked-members',
  templateUrl: './unlinked-members.component.html',
  styleUrls: ['./unlinked-members.component.css']
})
export class UnlinkedMembersComponent implements OnChanges {
  @Input() assetgroup: string;
  @Input() metagroup: string;
  @Input() sortAscending = true;
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  options: [{value: string, name: string, checked: boolean}];
  entities: Member[] = new Array();
  entities$: Observable<Member[]>;
  private reloadentities$: Observable<Member[]>;
  filteredEntities$: Observable<Member[]>;
  private linkedEntitiesSub: Subscription;
  processing = false;
  selectAll = false;
  filterCallback = this.assetgroupService.filterEntities;
  totalEntities = 0;
  selectedEntitiesCount = 0;

  constructor(
      private metagroupService: MetagroupService, private assetgroupService: AssetgroupService) {}

  ngOnChanges() {
    if (this.metagroup && this.assetgroup) {
      this.reloadentities$ = this.metagroupService.getMembers(this.metagroup, this.assetgroup);
      this.entities$ = this.reloadentities$.pipe(
          publishReplay(), refCount(),
          map(entity => entity.sort((a, b) => a.label.localeCompare(b.label))));
      this.filteredEntities$ = this.entities$;
      this.entities$.subscribe((entities) => {
        this.entities = entities;
        this.totalEntities = entities.length;
      });
    }
  }
  onSubmit() {
    const batchSize = 100;  // Set the desired batch size
    const selectedEntities = this.entities.filter((entity) => entity.checked);
    this.selectedEntitiesCount = selectedEntities.length;
    const numBatches = Math.ceil(selectedEntities.length / batchSize);
    for (let i = 0; i < numBatches; i++) {
      const start = i * batchSize;
      const end = start + batchSize;
      const batchEntities = selectedEntities.slice(start, end);

      const arrayObservables: Observable<any>[] = batchEntities.map((member) => {
        return this.assetgroupService.linkEntity(this.assetgroup, member);
      });

      if (arrayObservables.length > 0) {
        console.log('Number of items to process in batch', arrayObservables.length);
        this.processing = true;
        const temporary$ = this.reloadentities$.pipe(
            publishReplay(),
            refCount(),
        );
        this.linkedEntitiesSub = observableForkJoin(arrayObservables)
                                     .pipe(concat(temporary$))
                                     .subscribe(
                                         () => {
                                           this.filteredEntities$ = temporary$;
                                           this.processing = false;
                                           this.onSave.emit(null);
                                         },
                                         () => {
                                           this.processing = false;
                                         });
      }
    }
  }

  abort() {
    if (this.linkedEntitiesSub) {
      this.linkedEntitiesSub.unsubscribe();
    }
    this.processing = false;
  }

  onSort() {
    this.entities = this.entities.sort(
        (a, b) =>
            this.sortAscending ? a.label.localeCompare(b.label) : b.label.localeCompare(a.label));
  }

  onCancelled() {
    this.abort();
    this.onCancel.emit(null);
  }

  noSelection() {
    return !this.entities.some((entity) => entity.checked ? entity.checked : false);
  }

  setFilteredEntities(filteredItems: Observable<Member[]>) {
    this.filteredEntities$ = filteredItems;
  }

  selectAllUnselectAll() {
    this.selectAll = !this.selectAll;
    this.entities.forEach(entity => entity.checked = this.selectAll);
    this.updateSelectedCount();
  }
  updateSelectedCount() {
    this.selectedEntitiesCount = this.entities.filter(entity => entity.checked).length;
  }
}
