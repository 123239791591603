<div class="form-group row">
    <label for="selectedprofile" class="col-sm-2 col-form-label" i18n>External System</label>
    <div class="col-10">
      <select *ngIf="(assetprofiles$ | async) as profiles" class="custom-select" id="selectedprofile" [(ngModel)]="selectedprofile" (change)="onSystemChange()" >
        <option *ngFor="let profile of profiles" [ngValue]="profile">{{ getLabel(profile)}} </option>
      </select>
    </div>
</div>
<div>
<xcm-dynamic-form *ngIf="selectedprofile" [questions]="questions" (onSave)="onSubmit($event)" (onCancel)="onCancelled()"></xcm-dynamic-form>
