import {Scope} from 'app/shared/models/scope';
import {Option} from 'app/shared/question-model/option';

export class ProfileElementFormat {
  id: string;
  label: string;
  controlType: string;
  options: Option[];
  regex: string;
  concealed: boolean;
  required: boolean;
  order: number;
  description: string;
  constructor() {
    this.options = [];
  }
}

export class ExternalSystem {
  system: string;
  subsystem: string;
}

export class AssetProfile {
  id?: string;
  label: string;
  externalSystem: ExternalSystem;
  scope: Scope;
  profileElement: ProfileElementFormat[];
  constructor() {
    this.scope = new Scope();
    this.externalSystem = new ExternalSystem();
    this.profileElement = [];
  }
}

export class AssetPayload {
  profileElementFormatId: string;
  value: string;
}

export class Asset {
  id?: string;
  profile: AssetProfile;
  payload: AssetPayload[];
  constructor() {
    this.profile = new AssetProfile();
    this.payload = [];
  }
}
