import {Metagroup} from 'app/assetgroup/metagroup/metagroup';
import * as MetagroupActions from 'app/reducers/metagroup.actions';

export type Action = MetagroupActions.All;
export type State = Metagroup;
export function reducer(state: Metagroup, action: Action): Metagroup {
  switch (action.type) {
    case MetagroupActions.CHANGE_CUSTOMER: {
      return action.payload;
    }

    default: { return state; }
  }
}
