<div class="xcm-content design-factory-v2">
    <xcm-header-nav></xcm-header-nav>
    <br> 
    <!--ng-progress [color]="'#B05555'"></ng-progress
        https://github.com/MurhafSousli/ngx-progressbar
    -->
    <ng-progress [showSpinner]="false" [color]="'#E33939'" [thick]="false" [ease]="'linear'"></ng-progress>
    <div class="container-fluid">
    <xcm-alert [timeout]="10000"></xcm-alert>
    <router-outlet></router-outlet>
    </div>
</div>
<xcm-footer></xcm-footer>



