<div class="modal-header">
    <h5 class="modal-title" >
    <ng-container [ngSwitch]="usecase">
        <ng-container *ngSwitchCase="deleteAssetgroup" i18n="@@assetgroup.delete_confirmation_title">Delete Assetgroup</ng-container>
        <ng-container *ngSwitchCase="deleteAsset" i18n="@@asset.delete_confirmation_title">Delete Asset</ng-container>
        <ng-container *ngSwitchCase="unlinkAssetgroup" i18n="@@assetgroup.unlink_confirmation_title">Unlink Assetgroup</ng-container>        
        <ng-container *ngSwitchDefault i18n="@@delete_confirmation_title">Delete</ng-container>
    </ng-container>
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    <ng-container [ngSwitch]="usecase">
        <ng-container *ngSwitchCase="deleteAssetgroup" i18n="@@assetgroup.delete_confirmation_message">You are about to delete the assetgroup along with its assets.<br/>Are you sure you want to delete the selected assetgroup?</ng-container>
        <ng-container *ngSwitchCase="deleteAsset" i18n="@@asset.delete_confirmation_message">You are about to remove asset from the assetgroup. <br/>Are you sure you want to remove the selected asset?</ng-container>
        <ng-container *ngSwitchCase="unlinkAssetgroup" i18n="@@assetgroup.unlink_confirmation_message">You are about to unlink assetgroup. <br/>Users will not be able to benefit from assetgroup anymore. <br/> Are you sure you want to unlink selected items?</ng-container>        
        <ng-container *ngSwitchDefault i18n="@@delete_confirmation_message">Confirm Deletion</ng-container>
    </ng-container>
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-danger mb-2 mb-md-0 mr-2" (click)="activeModal.close(true)" i18n="@@action.yes">Yes</button>
  <button type="button" class="btn btn-outline-dark mb-2 mb-md-0 mr-2" (click)="activeModal.close(false)" i18n="@@action.no">No</button>
</div>
