import {CommonModule} from '@angular/common';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {MetagroupsResolver} from 'app/assetgroup/metagroup/metagroups-resolver.service';
import {ScopesResolver} from 'app/assetgroup/scope/scopes-resolver.service';
import {ErrorHandlerService} from 'app/core/error-hanlder.service';
import {AuthenticationService} from 'app/login/authentication.service';
import {QuestionControlService} from 'app/shared/dynamic-form/question-control.service';
import {DfModalService} from 'design-factory-v2';

import {AlertService} from './alert.service';
import {AssetgroupService} from './assetgroup.service';
import {AssetprofileService} from './assetprofile.service';
import {AuthenticationGuardService} from './authentication-guard.service';
import {AuthorizationService} from './authorization.service';
import {MetagroupService} from './metagroup.service';
import {ScopeService} from './scope.service';
import {ValidationService} from './validation.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    DfModalService, AuthenticationService, AlertService, ErrorHandlerService, ScopeService,
    MetagroupService, AssetgroupService, AuthenticationGuardService, AssetprofileService,
    QuestionControlService, ScopesResolver, AuthorizationService, ValidationService,
    MetagroupsResolver
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in AppModule only');
    }
  }
}
