import * as tslib_1 from "tslib";
import * as ControlType from 'app/shared/question-model/control-type';
import { SingleSelectQuestion } from './question-single-select';
var DropdownQuestion = /** @class */ (function (_super) {
    tslib_1.__extends(DropdownQuestion, _super);
    function DropdownQuestion(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.controlType = ControlType.DROPDOWN;
        ;
        return _this;
        // WARN Do not bind radio type in template, it should be explicitely type="radio" and not
        // [type]="option.type"
    }
    return DropdownQuestion;
}(SingleSelectQuestion));
export { DropdownQuestion };
