  <ng-container [ngSwitch]="question.controlType">
    <ng-container *ngSwitchCase="'TEXTBOX'">
      <td class="info-label">{{ question.label }}</td>
      <td>{{ getQuestionValue() }}</td>
    </ng-container>
    <ng-container *ngSwitchCase="'CHECKBOX'">
      <td  class="info-label">{{ question.label }}</td>
      <td *ngIf="question.value; else deactivated" class="badge badge-success" i18n>ACTIVATED</td>
      <ng-template #deactivated ><td class="badge badge-default" i18n>DEACTIVATED</td></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'RADIOS'">
      <td  class="info-label" >{{ question.label }}</td>
      <td>{{getOptionLabel(question.value)}}</td>
    </ng-container>
    <ng-container *ngSwitchCase="'DROPDOWN'">      
        <td class="info-label">{{ question.label }}</td>
        <td>{{ getOptionLabel(question.value) }}</td>
    </ng-container>
    <ng-container *ngSwitchCase="'MULTISELECT'">
      <td  class="info-label">{{ question.label }}</td>
      <td>
        <ul class="list-unstyled">
          <li *ngFor="let option of question.value">{{getOptionLabel(option)}}</li>
        </ul>
      </td>
    </ng-container>   
  </ng-container>

