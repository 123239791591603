import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { isDevMode } from '@angular/core';
import { ConfirmModalComponent } from 'app/assetgroup/confirm-modal/confirm-modal.component';
import { ErrorHandlerService } from 'app/core/error-hanlder.service';
import { DfModalService } from 'design-factory-v2';
import { from as observableFrom } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';
var AssetgroupService = /** @class */ (function () {
    function AssetgroupService(http, errorHandlerService, modalService) {
        this.http = http;
        this.errorHandlerService = errorHandlerService;
        this.modalService = modalService;
        this.apiUrl = '/v1/xcm/assetgroups';
    }
    AssetgroupService.prototype.getAssets = function (assetgroupId) {
        return this.http.get(this.apiUrl + "/" + assetgroupId + "/assets")
            .pipe(map(function (res) { return res.data || (res.warnings ? [] : res); }), catchError(this.errorHandlerService.handleError));
    };
    AssetgroupService.prototype.getAsset = function (assetgroupId, assetId) {
        return this.http.get(this.apiUrl + "/" + assetgroupId + "/assets/" + assetId)
            .pipe(map(function (res) { return res.data || res; }), catchError(this.errorHandlerService.handleError));
        ;
    };
    AssetgroupService.prototype.getAssetgroups = function (scope, metagroupId) {
        return this.http
            .get(this.apiUrl + "?scope=" + scope + "&metagroupId=" + metagroupId)
            .pipe(map(function (res) { return res.data; }), catchError(this.errorHandlerService.handleError));
    };
    AssetgroupService.prototype.getAssetgroup = function (assetgroupId) {
        return this.http.get(this.apiUrl + "/" + assetgroupId)
            .pipe(map(function (res) { return res.data; }), catchError(this.errorHandlerService.handleError));
    };
    AssetgroupService.prototype.updateAssetgroup = function (assetgroup) {
        return this.http.put(this.apiUrl + "/" + assetgroup.id, assetgroup)
            .pipe(tap(this.errorHandlerService.handleWarning), catchError(this.errorHandlerService.handleError));
    };
    AssetgroupService.prototype.addAssetgroup = function (assetgroup) {
        return this.http.post("" + this.apiUrl, assetgroup)
            .pipe(tap(this.errorHandlerService.handleWarning), catchError(this.errorHandlerService.handleError));
    };
    AssetgroupService.prototype.getMembers = function (assetgroupId) {
        return this.http.get(this.apiUrl + "/" + assetgroupId + "/members")
            .pipe(map(function (res) { return res.data; }), catchError(this.errorHandlerService.handleError));
        ;
    };
    AssetgroupService.prototype.linkEntity = function (assetgroupId, member) {
        if (isDevMode()) {
            delete member.checked;
            member['assetgroupId'] = assetgroupId;
            return this.http.put(this.apiUrl + "/" + assetgroupId + "/members/" + member.id, member)
                .pipe(catchError(this.errorHandlerService.handleError));
        }
        else {
            return this.http.post(this.apiUrl + "/" + assetgroupId + "/members", member)
                .pipe(tap(this.errorHandlerService.handleWarning), catchError(this.errorHandlerService.handleError));
        }
    };
    AssetgroupService.prototype.unlinkEntity = function (assetgroupId, memberId) {
        if (isDevMode()) {
            var member = { assetgroupId: 'NaN' };
            return this.http.patch(this.apiUrl + "/" + assetgroupId + "/members/" + memberId, member)
                .pipe(catchError(this.errorHandlerService.handleError));
        }
        else {
            return this.http.delete(this.apiUrl + "/" + assetgroupId + "/members/" + memberId)
                .pipe(tap(this.errorHandlerService.handleWarning), catchError(this.errorHandlerService.handleError));
        }
    };
    AssetgroupService.prototype.deleteAsset = function (assetgroupId, assetId) {
        return this.http.delete(this.apiUrl + "/" + assetgroupId + "/assets/" + assetId)
            .pipe(tap(this.errorHandlerService.handleWarning), catchError(this.errorHandlerService.handleError));
    };
    AssetgroupService.prototype.addAsset = function (assetgroupId, asset) {
        return this.http.post(this.apiUrl + "/" + assetgroupId + "/assets", asset)
            .pipe(tap(this.errorHandlerService.handleWarning), catchError(this.errorHandlerService.handleError));
    };
    AssetgroupService.prototype.updateAsset = function (assetgroupId, asset) {
        var _loop_1 = function (elementFormat) {
            if (elementFormat.concealed) {
                var idx = asset.payload.findIndex(function (assetPayload) { return assetPayload.profileElementFormatId === elementFormat.id; });
                if (idx !== -1 && asset.payload[idx].value.startsWith('*****')) {
                    // Removing from payload the profileElements that are concealed with at least 5 stars
                    // as we don't want them to be updated in the DB
                    asset.payload.splice(idx, 1);
                }
            }
        };
        for (var _i = 0, _a = asset.profile.profileElement; _i < _a.length; _i++) {
            var elementFormat = _a[_i];
            _loop_1(elementFormat);
        }
        return this.http.put(this.apiUrl + "/" + assetgroupId + "/assets/" + asset.id, asset)
            .pipe(tap(this.errorHandlerService.handleWarning), catchError(this.errorHandlerService.handleError));
    };
    AssetgroupService.prototype.setDefaultEntity = function (assetgroupId, memberId, isDefault) {
        return this.http
            .patch(this.apiUrl + "/" + assetgroupId + "/members/" + memberId, { default: isDefault })
            .pipe(tap(this.errorHandlerService.handleWarning), catchError(this.errorHandlerService.handleError));
    };
    AssetgroupService.prototype.deleteAssetgroup = function (assetgroupId) {
        return this.http.delete(this.apiUrl + "/" + assetgroupId)
            .pipe(tap(this.errorHandlerService.handleWarning), catchError(this.errorHandlerService.handleError));
    };
    AssetgroupService.prototype.showConfirmDialog = function (usecase) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var action, modalRef, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        action = false;
                        return [4 /*yield*/, this.modalService.open(ConfirmModalComponent)];
                    case 1:
                        modalRef = _a.sent();
                        modalRef.componentInstance.usecase = usecase;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, modalRef.result];
                    case 3:
                        action = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        action = false;
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, action];
                }
            });
        });
    };
    AssetgroupService.prototype.confirmDialog = function (usecase) {
        return observableFrom(this.showConfirmDialog(usecase));
    };
    AssetgroupService.prototype.filterAssetgroups = function (assetgroups, term) {
        return observableFrom(assetgroups)
            .pipe(filter(function (assetgroup) { return assetgroup.label.toLowerCase().startsWith(term.toLowerCase()); }), toArray());
    };
    AssetgroupService.prototype.filterAssets = function (assets, term) {
        return observableFrom(assets).pipe(filter(function (asset) { return asset.profile.label.toLowerCase().includes(term.toLowerCase()); }), toArray());
    };
    AssetgroupService.prototype.filterEntities = function (members, term) {
        return observableFrom(members).pipe(filter(function (member) { return member.label.toLowerCase().includes(term.toLowerCase()); }), toArray());
    };
    return AssetgroupService;
}());
export { AssetgroupService };
