import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MetagroupsResolver} from 'app/assetgroup/metagroup/metagroups-resolver.service';
import {ScopesResolver} from 'app/assetgroup/scope/scopes-resolver.service';
import {AuthenticationGuardService} from 'app/core/authentication-guard.service';
import {AssetProfilesResolver} from 'app/provider-profile/asset-profiles-resolver.service';
import {MetagroupProfileComponent} from 'app/provider-profile/metagroup-profile/metagroup-profile.component';
import {MetagroupsOverviewComponent} from 'app/provider-profile/metagroups-overview/metagroups-overview.component';
import {ProviderProfileComponent} from 'app/provider-profile/provider-profile/provider-profile.component';
import {XcmConfigComponent} from 'app/provider-profile/xcm-config/xcm-config.component';

const routes: Routes = [{
  path: '',
  resolve: {grantedScopes: ScopesResolver},
  canActivate: [AuthenticationGuardService],
  children: [
    {
      path: 'xcm-config',
      component: XcmConfigComponent,
      children: [{
        path: 'scope/:scope',
        resolve: {},
        children: [
          {
            path: 'metagroups/:id',
            resolve: {profiles: AssetProfilesResolver},
            component: MetagroupProfileComponent
          },
          {
            path: 'metagroups',
            resolve: {metagroups: MetagroupsResolver},
            component: MetagroupsOverviewComponent
          }
        ]
      }]
    },
    {path: '', component: ProviderProfileComponent}
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AssetProfilesResolver]
})
export class ProviderProfileRoutingModule {
}
