/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./metagroup-profile.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../profile-list/profile-list.component.ngfactory";
import * as i4 from "../profile-list/profile-list.component";
import * as i5 from "./metagroup-profile.component";
import * as i6 from "@angular/router";
import * as i7 from "../../core/assetprofile.service";
var styles_MetagroupProfileComponent = [i0.styles];
var RenderType_MetagroupProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MetagroupProfileComponent, data: {} });
export { RenderType_MetagroupProfileComponent as RenderType_MetagroupProfileComponent };
function View_MetagroupProfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n", " ", "\n"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; var currVal_1 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_MetagroupProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_MetagroupProfileComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "btn btn-primary mb-2 mb-md-0 mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showProfiles() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-plus fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add a Profile to metagroup"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "row top-buffer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "xcm-profile-list", [], null, null, null, i3.View_ProfileListComponent_0, i3.RenderType_ProfileListComponent)), i1.ɵdid(9, 573440, null, 0, i4.ProfileListComponent, [], { showButton: [0, "showButton"], profiles: [1, "profiles"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.profilesToLink$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = false; var currVal_2 = i1.ɵunv(_v, 9, 1, i1.ɵnov(_v, 10).transform(_co.profiles$)); _ck(_v, 9, 0, currVal_1, currVal_2); }, null); }
export function View_MetagroupProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-metagroup-profile", [], null, null, null, View_MetagroupProfileComponent_0, RenderType_MetagroupProfileComponent)), i1.ɵdid(1, 114688, null, 0, i5.MetagroupProfileComponent, [i6.ActivatedRoute, i7.AssetprofileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MetagroupProfileComponentNgFactory = i1.ɵccf("xcm-metagroup-profile", i5.MetagroupProfileComponent, View_MetagroupProfileComponent_Host_0, {}, {}, []);
export { MetagroupProfileComponentNgFactory as MetagroupProfileComponentNgFactory };
