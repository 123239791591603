import {Option} from './option';
import {QuestionBase} from './question-base';


export class SingleSelectQuestion extends QuestionBase<string> {
  options: Option[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
  }
}
