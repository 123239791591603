/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./asset-edit.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../shared/dynamic-form/dynamic-form.component";
import * as i4 from "../../../shared/dynamic-form/question-control.service";
import * as i5 from "@angular/common";
import * as i6 from "./asset-edit.component";
import * as i7 from "../../../core/assetprofile.service";
var styles_AssetEditComponent = [i0.styles];
var RenderType_AssetEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssetEditComponent, data: {} });
export { RenderType_AssetEditComponent as RenderType_AssetEditComponent };
function View_AssetEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-dynamic-form", [], null, [[null, "onSave"], [null, "onCancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("onCancel" === en)) {
        var pd_1 = (_co.onCancelled() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 573440, null, 0, i3.DynamicFormComponent, [i4.QuestionControlService], { questions: [0, "questions"] }, { onSave: "onSave", onCancel: "onCancel" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.questions; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AssetEditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetEditComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.asset; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AssetEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-asset-edit", [], null, null, null, View_AssetEditComponent_0, RenderType_AssetEditComponent)), i1.ɵdid(1, 638976, null, 0, i6.AssetEditComponent, [i7.AssetprofileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssetEditComponentNgFactory = i1.ɵccf("xcm-asset-edit", i6.AssetEditComponent, View_AssetEditComponent_Host_0, { asset: "asset" }, { onSave: "onSave", onCancel: "onCancel" }, []);
export { AssetEditComponentNgFactory as AssetEditComponentNgFactory };
