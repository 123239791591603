import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {ProviderProfileModule} from 'app/provider-profile/provider-profile.module';
import {reducers} from 'app/reducers/reducers';
import {AlertComponent} from 'app/shared/alert/alert.component';
import {NgProgressInterceptor, NgProgressModule} from 'ngx-progressbar';
import {TreeviewModule} from 'ngx-treeview';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AssetgroupModule} from './assetgroup/assetgroup.module';
import {CoreModule} from './core/core.module';
import {FooterComponent} from './footer/footer.component';
import {HeaderNavComponent} from './header-nav/header-nav.component';
import {AuthenticationInterceptor} from './login/authentication.interceptor';


@NgModule({
  declarations: [AppComponent, AlertComponent, HeaderNavComponent, FooterComponent],

  imports: [
    NgbModule, TreeviewModule.forRoot(), BrowserModule, StoreModule.forRoot(reducers),
    EffectsModule.forRoot([]), HttpClientModule, CoreModule, AssetgroupModule,
    ProviderProfileModule, AppRoutingModule, NgProgressModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
