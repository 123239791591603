/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./assetgroup-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../assetgroup-form/assetgroup-form.component.ngfactory";
import * as i4 from "../assetgroup-form/assetgroup-form.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../core/validation.service";
import * as i7 from "@angular/router";
import * as i8 from "./assetgroup-header.component";
import * as i9 from "../../core/assetgroup.service";
var styles_AssetgroupHeaderComponent = [i0.styles];
var RenderType_AssetgroupHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssetgroupHeaderComponent, data: {} });
export { RenderType_AssetgroupHeaderComponent as RenderType_AssetgroupHeaderComponent };
function View_AssetgroupHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CASCADE"]))], null, null); }
function View_AssetgroupHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-dark"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["NOT CASCADED"]))], null, null); }
function View_AssetgroupHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["aria-label", "Edit"], ["class", "btn btn-lg btn-outline-primary mb-2 mb-md-0 mr-2 test-edit-assetgroup"], ["href", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.preventDefault();
        var pd_0 = (_co.onEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fa fa-pencil fa-lg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Edit"]))], null, null); }
function View_AssetgroupHeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["aria-label", "Delete"], ["class", "btn btn-lg btn-outline-danger mb-2 mb-md-0 mr-2 test-delete-assetgroup"], ["href", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.preventDefault();
        var pd_0 = (_co.onDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fa fa-trash fa-lg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Delete"]))], null, null); }
function View_AssetgroupHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "display-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetgroupHeaderComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["deactivated", 2]], null, 0, null, View_AssetgroupHeaderComponent_3)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetgroupHeaderComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssetgroupHeaderComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.assetgroup.cascade; var currVal_2 = i1.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_1, currVal_2); var tmp_3_0 = null; var currVal_3 = (((tmp_3_0 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.authorizations$))) == null) ? null : tmp_3_0.U_ASSETGROUP); _ck(_v, 9, 0, currVal_3); var tmp_4_0 = null; var currVal_4 = (((tmp_4_0 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(_co.authorizations$))) == null) ? null : tmp_4_0.D_ASSETGROUP); _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.assetgroup.label; _ck(_v, 2, 0, currVal_0); }); }
function View_AssetgroupHeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "xcm-assetgroup-form", [], null, [[null, "onSave"], [null, "onCancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("onCancel" === en)) {
        var pd_1 = (_co.onCancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_AssetgroupFormComponent_0, i3.RenderType_AssetgroupFormComponent)), i1.ɵdid(2, 114688, null, 0, i4.AssetgroupFormComponent, [i5.FormBuilder, i6.ValidationService], { assetgroup: [0, "assetgroup"] }, { onSave: "onSave", onCancel: "onCancel" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.assetgroup; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AssetgroupHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "section", [["class", "jumbotron text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "jumbotron-heading"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetgroupHeaderComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssetgroupHeaderComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "a", [["class", "carousel-control-prev test-back"], ["data-slide", "prev"], ["role", "button"], ["routerLink", ".."]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-left fa-4x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Previous"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editMode; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.editMode; _ck(_v, 5, 0, currVal_1); var currVal_4 = ".."; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 7).target; var currVal_3 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_2, currVal_3); }); }
export function View_AssetgroupHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xcm-assetgroup-header", [], null, null, null, View_AssetgroupHeaderComponent_0, RenderType_AssetgroupHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i8.AssetgroupHeaderComponent, [i9.AssetgroupService, i7.Router, i7.ActivatedRoute, i5.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssetgroupHeaderComponentNgFactory = i1.ɵccf("xcm-assetgroup-header", i8.AssetgroupHeaderComponent, View_AssetgroupHeaderComponent_Host_0, { assetgroup: "assetgroup" }, {}, []);
export { AssetgroupHeaderComponentNgFactory as AssetgroupHeaderComponentNgFactory };
