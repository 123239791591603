import {HttpParams} from '@angular/common/http';
import {SapConfig} from 'app/login/sap.config';

export interface AuthParams {
  service: string;
  clientId: string;
  nonce?: string;
  redirectUri?: string;
  scope: string;
  responseType?: string;
}

export class Auth {
  private params: AuthParams;

  static genRandom(length: number): string {
    const s = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return Array.apply(null, Array(length))
        .map(() => s.charAt(Math.floor(Math.random() * s.length)))
        .join('');
  }

  constructor() {
    this.params = {
      service: 'XCM',
      nonce: Auth.genRandom(16),
      clientId: this.guessPhaseFromLocation() === 'usr' ? SapConfig.localSap : 'XCMCID',
      redirectUri: window.location
                       .href,  // origin || `${window.location.protocol}//${window.location.host}`,
      scope: 'openid locale'
    }
  }

  url(phase: string = ''): string {
    let prefix: string;
    if (phase === 'prd') {
      prefix = 'www';
    } else if (phase === 'usr') {
      prefix = SapConfig.localLoginPhase;
    } else {
      prefix = phase;
    }
    const url = `https://${prefix}.accounts.amadeus.com/LoginService/authorizeAngular`;

    let urlParams = new HttpParams()
                        .set('service', this.params.service)
                        .set('client_id', this.params.clientId)
                        .set('scope', this.params.scope);
    if (this.params.redirectUri) {
      urlParams = urlParams.set('redirect_uri', this.params.redirectUri);
    }
    if (this.params.responseType) {
      urlParams = urlParams.set('response_type', this.params.responseType);
    }
    if (this.params.nonce) {
      urlParams = urlParams.set('nonce', this.params.nonce);
    }

    return `${url}?${urlParams.toString()}`;
  }

  guessPhaseFromLocation(): string {
    let phase = 'usr';  // default phase
    let host = location.host;
    if (host.startsWith('www.')) {
      host = host.substring(4);
    }
    if (host.endsWith('amadeus.com')) {
      const chunks = host.split('.');
      if (chunks.length > 0) {
        phase = (chunks[0] === 'xcm') ? 'prd' : chunks[0];
      }
    }
    return phase;
  }
}
