<div *ngIf="assetgroup">
  <div class="row">
    <div class="col">
      <xcm-assetgroup-header [assetgroup]="assetgroup"></xcm-assetgroup-header>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ngb-tabset>
          <ngb-tab title="Assets">
            <ng-template ngbTabContent>
              <div class="row">
                <div class="col">
                  <h1>Assets
                    <span *ngIf="(authorizations$ | async).C_ASSET" (click)="toggleAsset()" class="btn btn-primary mb-2 mb-md-0 mr-2 pull-right"><i [class.fa-plus]="!showAssetForm" [class.test-add-asset]="!showAssetForm" [class.test-cancel-asset]="showAssetForm" [class.fa-ban]="showAssetForm" class="fa fa-fw"></i>Add Asset</span>
                  </h1> 
                  <xcm-asset-create *ngIf="showAssetForm; else showAssetList" [assetgroupId]="assetgroup.id" (onCancel)="exitAssetForm()" (onSave)="exitAssetForm()"></xcm-asset-create>
                  <ng-template #showAssetList><xcm-asset-list  [assetgroup]="assetgroup.id"></xcm-asset-list></ng-template>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>Linked Entities</ng-template>
            <ng-template ngbTabContent>  
              <div class="row">
              <div class="col">
                <h1>Linked Entities
                  <span *ngIf="(authorizations$ | async).U_AG_ENTITIES" (click)="toggleEntities()" class="btn btn-primary mb-2 mb-md-0 mr-2 pull-right"><i [class.fa-plus]="!showEntitiesForm" [class.test-link-entities]="!showEntitiesForm" [class.test-cancel-link-entities]="showEntitiesForm" [class.fa-ban]="showEntitiesForm" class="fa fa-fw"></i>Add entity</span></h1> 
                <xcm-unlinked-members  *ngIf="showEntitiesForm; else showlinkedmembers" [metagroup]="assetgroup.metagroup?.id" [assetgroup]="assetgroup.id" (onCancel)="exitEntitiesForm()" (onSave)="exitEntitiesForm()"></xcm-unlinked-members>      
                <ng-template #showlinkedmembers><xcm-members [assetgroup]="assetgroup.id"></xcm-members></ng-template>
              </div>
            </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
    </div>
  </div>
</div>
