export class QuestionBase<T> {
  value: T;
  id: string;          /** unique identifier */
  label: string;       /** label of the input to display */
  required: boolean;   /** required validator */
  order: number;       /** position of the field */
  controlType: string; /** out of TEXTBOX, CHECKBOX, DROPDOWN, RADIOS, MULTISELECT */

  constructor(options: {
    value?: T,
    id?: string,
    label?: string,
    required?: boolean,
    order?: number,
    controlType?: string
  } = {}) {
    if (options.value) {
      this.value = options.value;
    }
    this.id = options.id || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
  }
}
