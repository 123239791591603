export interface Authorization {
  label: string;
  allow: boolean;
}


export class AuthorizationData {
  C_ASSETGROUP: boolean;
  D_ASSETGROUP: boolean;
  R_ASSETGROUP: boolean;
  U_ASSETGROUP: boolean;
  C_ASSET: boolean;
  D_ASSET: boolean;
  R_ASSET: boolean;
  R_MGP_ENTITIES: boolean;
  R_AG_ENTITIES: boolean;
  U_AG_ENTITIES: boolean;
  R_ASSETPROFILE: boolean;
  U_PAYLOAD: boolean;
  R_PROFILE_TAB: boolean;
}
