<button type="button" (click)="back()" class="btn btn-outline-primary mb-2 mb-md-0 mr-2"><i class="fa fa-chevron-left fa-fw"></i>Back</button>
<div class="row top-buffer">
  <div class="col">
      <ng-container *ngIf="asset$ | async as asset">
          <ng-container *ngIf="!editMode">
               <h4>Working on {{ asset.profile?.label }} <a *ngIf="(authorizations$ | async).U_PAYLOAD" class="btn btn-default" href="" (click)="$event.preventDefault();onEdit();" aria-label="Edit">
                  <i class="fa fa-pencil fa-2x" aria-hidden="true"></i>
                </a></h4>
              <xcm-asset-detail [asset]="asset"></xcm-asset-detail>
          </ng-container>
          
          <xcm-asset-edit *ngIf="editMode" [asset]="asset" (onCancel)="exitEditMode()" (onSave)="onSave($event)"></xcm-asset-edit>
          </ng-container>
  </div>
</div>
